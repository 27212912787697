.no-data-box {
  width: 100%;
  padding: 1.5rem;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
  border: 1px solid #e6e6e6;
  pointer-events: none;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  margin: 10px 0;

  p {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 500;
    color: #7f7f7f;
  }
}

.schedule-time-wrap {
  padding-right: 25px;

  .schedule-time {
    border-top: 1px solid var(--Grey, #E6E6E6);
    background: var(--Prime-Background, #FAF9F7);
    padding: 10px;

    h5 {
      color: $light-full_Black;
      font-family: "Gilroy-Bold", serif;
      font-size: 14px;

      line-height: 22px;
      letter-spacing: -0.14px;
    }

    .submint-btn {
      width: 200px;
      height: 44px;
      padding: 14px;
      @include flex;
      @include flex-justify;
      @include flex-align;
      gap: 10px;
      color: $light-full_White;
      text-align: center;
      font-family: "Gilroy-Bold", sans-serif;
      font-size: 12px;

      text-transform: uppercase;
      border-radius: 5px;
      background: $light-chilly_red;
      margin-top: 15px;
    }

    .time-wrap {
      @include flex;
      @include flex-align;
      @include flex-justify(space-between);

      @media (max-width:767px) {
        gap: 10px 0;
      }

      span {
        font-weight: bold;
        color: $light-full_Black;
        font-family: "Gilroy-Regular", sans-serif;
        font-size: 12px;

        line-height: 22px;
        letter-spacing: -0.12px;
      }

      .time-time {
        @include flex;
        @include flex-align;
        gap: 10px;


        select {
          height: 34px;
          padding: 6px 15px;
          border-radius: 5px;
          border: 1px solid $light-light_grey;
          color: $light-full_Black;
          text-align: center;
          font-family: "Gilroy-Medium", sans-serif;
          font-size: 12px;
          font-weight: 400;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background: transparent url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/down-arrow.svg) no-repeat !important;
          background-position: right 5px center !important;

          &:focus {
            outline: none;
            box-shadow: none;
          }

        }

        .btn-group {
          width: 70px;
          gap: 0;
          border-radius: 5px;
          border: 1px solid $light-light_grey;
          overflow: hidden;

          button {
            padding: 10px 8px;
            color: $light-full_Black;
            text-align: center;
            font-family: "Gilroy-Medium", sans-serif;
            font-size: 10px;
            font-weight: 400;
            border-radius: 0;
            transition: all 0.5s ease-in-out;

            &.isAtive {
              background: $dark-chilly_red;
              color: $light-negative;
            }
          }
        }
      }

    }
  }

  .opening-time {
    border-radius: 5px;
    background: $light-amber_card_background;
    padding: 1px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
    flex-wrap: wrap;

    @media (max-width:480px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }

    p {
      color: $light-full_Black;
      font-family: "Gilroy-Medium", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;

      span {
        color: $light-amber;
      }
    }
  }
}

.time-picker {
  display: flex;
  align-items: center;

  &__inputs {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    width: 62px;
    overflow: hidden;
    justify-content: center;
  }

  &__input {
    width: 20px;
    height: 32px;
    text-align: center;
    border: none;
    outline: none;
    background: var(--Prime-Background, #FAF9F7);
    color: var(--Black, #000);
    font-family: "Gilroy-Bold", sans-serif;
    font-size: 12px;

    &--error {
      border-color: #f44336;
    }
  }

  &__separator {
    margin: 0 2px;
  }

  &__period-buttons {
    display: flex;
    margin-left: 8px;
  }

  &__button {
    outline: none;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 10px;
    font-weight: 400;
    border-radius: 0;
    border: 1px solid #E6E6E6;

    &:first-child {
      border-right: 0;
      border-radius: 5px 0px 0px 5px;
    }

    &:nth-child(2) {
      border-left: 0;
      border-radius: 0px 5px 5px 0px;
    }

    &--active {
      background-color: #be1622;
      border: 1px solid #be1622;
      color: #fff;
    }

    &:hover:not(&--active) {
      background-color: #e6e6e6;
    }
  }

  &__error {
    color: #f44336;
    margin-top: 4px;
  }
}

.time-picker__period-buttons {
  border-radius: 5px;
  overflow: hidden;
  height: 34px;
}

.p1 {
  padding: 0.5rem;
}

.p2 {
  padding: 1rem;
}

.p3 {
  padding: 1.5rem;
}

textarea {
  resize: false;
}

.no-data-container {
  height: auto;
  width: 100%;
  padding: 2rem;
  background: #faf9f7;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width:520px) {
    padding: 10px;
  }

  .no-data-content {
    display: grid;
    place-items: center;
    width: 100%;
    max-width: 40rem;
    border-radius: 1rem;
    padding: 3rem;
    text-align: center;

    @media (max-width:520px) {
      padding: 10px;
    }

    .no-data-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .icon {
        margin-bottom: 2rem;
        font-size: 3rem;
      }

      .text-block {
        margin-top: 2rem;

        h3 {
          color: #000;
          text-align: center;
          font-family: "Gilroy-ExtraBold", sans-serif;
          font-size: 20px;

          @media (max-width:1440px) {
            font-size: 18px;
          }
        }

        p {
          padding-top: 10px;
          color: #000 !important;
          text-align: center;
          font-family: "Gilroy-Regular", sans-serif;
          font-size: 14px;
        }

        button {
          border-radius: 9px;
          background: #be1622;
          width: 252px;
          height: 49px;
          padding: 17px;
          text-align: center;
          color: #fff;
          font-family: "Gilroy-Bold", sans-serif;
          font-size: 12px;
          text-transform: uppercase;
          margin: 20px auto auto;
          display: block;

          @media (max-width:520px) {
            width: 100%;
          }

          &:hover {
            transform: scale(1.05);
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.test-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; // Space between the SVGs
  justify-content: center;
  padding: 20px;
}

.test-icons svg {
  display: block; // Removes inline behavior
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100px; // Adjust the size of your SVG
  height: 100px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
  }
}

.takeAwayAr {
  direction: ltr;
}

.store-no-available-text {
  color: #f39200;
  font-size: 12px;
  padding: 0 0.5rem 0.5rem 0.5rem;
  font-family: "Gilroy-Medium", sans-serif;

}

.cartitems-wrap {
  .time-picker {
    width: 100%;

    .time-picker__inputs {
      width: 50%;

      &.__input {
        width: 100%;
      }
    }
  }
}

.cart-error-message {
  margin-top: 20px;
}

.outlets {
  .slick-track {
    margin-left: 0;
  }

  h5 {
    padding: 0 0.5rem 0.5rem 0.5rem;
    color: #ffffff;

    @media (max-width:991px) {
      padding: 0;
      padding-top: 5px;
      font-size: 14px;
    }
  }
}

.payment-disabled {
  background-color: #d0d0d0;
  position: relative;
  left: 3px;
  opacity: 0.7;
  cursor: not-allowed;
  border-radius: 8px;
}

.category-banner {
  .like-btn {

    &.isActive,
    &:hover {
      svg {
        path {
          fill: $light-chilly_red !important;
        }
      }
    }
  }
}

.mt-0-5rem {
  margin-top: 0.5rem;
}

.no-place-found {
  height: auto;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.5rem;
  flex-direction: column;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}