$NEXT_PUBLIC_CDN_URL: 'https://qa-chicking-cdn.emvigotech.co.uk';
@import '../styles/colors';
@import '../styles/dark_mode_switch';
@import '../styles/direction';
@import '../styles/fonts';

// globals css for globals style
@import './globals.scss';
@import './custom.scss';


.input-group {
    position: relative;
    @include flex;
    @include flex-align;
    @include flex-justify(flex-end);
    border-radius: 8px;
    border: 1px solid $light-light_grey;
    background: $light-negative;
    height: 44px;
    width: 100%;
    padding: 12px 15px;

    input {
        border: none !important;
        border-radius: unset !important;
        background: transparent !important;
        width: 100%;
        padding: 0;
    }
}

.formControl {
    &:focus {
        outline: none;
    }
}

.items {
    @include flex;
    @include flex-justify;
    @include flex-direction(column);
    cursor: pointer;

    .item-bg {
        margin: auto;
        transition: all 0.3s ease-in-out;

        &:hover {
            transform: scale(1.2);
        }

        figure {
            width: 100px;
            height: 100px;
            padding: 10px;
            @include flex;
            @include flex-justify;
            @include flex-align;
            border-radius: 8px;
            background: $light-amber;
            box-shadow: 0px 29px 50px 0px rgba(0, 0, 0, 0.16);
            margin: auto;

            @media(max-width:1366px) {
                width: 85px;
                height: 85px;
                box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.16);
            }

            @media(max-width:1024px) {
                width: 85px;
                height: 85px;
                box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.16);
            }


            img {
                width: 72px;
                height: 72px;
                border-radius: 8px;

                @media (max-width:1440px) {
                    width: 65px;
                    height: 65px;
                    border-radius: 4px;
                }
            }
        }
    }

    span {
        color: $light-full_Black;
        text-align: center;
        font-family: "Gilroy-Medium", sans-serif;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.16px;
        margin-top: 20px;

        @media (max-width:1440px) {
            font-size: 14px;
            margin-top: 10px;
        }

    }
}

.slider-container {
    .item {
        padding: 0 12px;
        width: 1224px !important;

        @media(max-width:1366px) {
            width: 920px !important;
        }

        @media(max-width:1024px) {
            width: 780px !important;
        }

        @media(max-width:800px) {
            width: 690px !important;
        }

        @media(max-width:767px) {
            width: 100% !important;
            padding: 0 30px;
        }

        &:focus-visible {
            border: none;
            outline: none;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 20px;

            @media(max-width:767px) {
                height: auto;
                border-radius: 10px;
            }

            &:focus-visible {
                border: none;
                outline: none;
            }
        }
    }

    .slick-prev {
        left: 10px;
    }

    .slick-next {
        right: 10px;
    }
}

.chicking-modal {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(11, 14, 23, 0.51);
    backdrop-filter: blur(11.5px);
    @include flex;
    @include flex-align;
    @include flex-direction(column);
    padding: 40px 0px;
    z-index: 9999;
    overflow: hidden;


    .chicking-modal-wraper {
        max-width: 590px;
        width: 90%;
        padding: 40px;
        @include flex;
        @include flex-align(flex-start);
        @include flex-direction(column);
        gap: 20px;
        border-radius: 5px;
        background: $light-negative;
        padding-right: 30px;

        @media (max-width:1600px) {
            padding: 30px;
            padding-right: 20px;
            gap: 15px;
        }

        @media (max-width:1366px) {
            padding: 20px;
            padding-right: 15px;
        }

        @media (max-width:991px) {
            gap: 10px;
            padding: 15px;
            padding-right: 5px;
        }

        .chicking-modal-header {
            width: 100%;
            padding-right: 10px;

            .chicking-modal-title {
                @include flex;
                @include flex-align(flex-start);
                @include flex-justify(space-between);

                h4 {
                    color: #2D2D2D;
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 24px;

                    @media (max-width:1600px) {
                        font-size: 22px;
                    }

                    @media (max-width:1366px) {
                        font-size: 18px;
                    }

                    @media (max-width:991px) {
                        font-size: 18px;
                    }
                }

                button {
                    padding: 0;
                }

                .back-btn-wrap {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                }

                .back-btn {
                    display: flex;
                    width: 32px;
                    height: 32px;
                    border-radius: 8px;
                    border: 1px solid $light-light_grey;
                    justify-content: center;
                    align-items: center;
                    transform-origin: center;
                    transform: rotate(180deg);

                    svg {
                        width: 12px;
                        height: 12px;
                    }
                }
            }

            p {
                color: #7D7A7A;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 14px;
                line-height: 23px;
                margin-top: 5px;

                @media (max-width:767px) {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }

        .chicking-modal-body {
            width: 100%;
            overflow: auto;
            height: auto;
            max-height: calc(100vh - 220px);
            padding-right: 10px;

            @media (max-width:767px) {
                max-height: calc(100vh - 320px);
            }


            .submint-btn {
                width: fit-content;
                height: 44px;
                @include flex;
                @include flex-justify;
                @include flex-align;
                gap: 10px;
                color: $light-full_White;
                text-align: center;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 12px;
                margin-left: 0;
                text-transform: uppercase;
                border-radius: 5px;
                background: $light-chilly_red;
                padding: 0 35px;
                transition: all 0.5s ease-in-out;

                &:hover {
                    background: $light-chilly_red_dark;
                }
            }
        }

        .chicking-modal-footer {
            padding-right: 10px;
            width: 100%;

            .btn-group {
                @include flex;
                @include flex-align;
                gap: 15px;
                margin: auto;
                margin-right: 0;

                button {
                    color: $light-full_Black;
                    text-align: center;
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 16px;
                    text-transform: uppercase;
                    display: flex;
                    width: 117px;
                    padding: 12px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    transition: all 0.5s ease-in-out;

                    @media (max-width:1600px) {
                        padding: 8px 12px;
                        font-size: 14px;
                    }

                    @media (max-width:991px) {
                        font-size: 12px;
                    }

                    &.close-btn {
                        border: 1px solid #a3a3a3;

                        &:hover {
                            background: $light-light_grey;
                        }
                    }

                    &.primary-btn {
                        background: $light-chilly_red;
                        color: $light-negative;

                        &:hover {
                            background: $light-chilly_red_dark;
                        }
                    }
                }
            }
        }
    }
}

.location-wrap {
    @include flex;
    @include flex-direction(column);
    gap: 30px;

    @media (max-width:1440px) {
        gap: 20px;
    }

    @media (max-width:991px) {
        gap: 15px;
    }

    .location-block {
        width: 100%;

        .recent-search {
            margin-top: 30px;

            @media (max-width:1440px) {
                margin-top: 20px;
            }

            @media (max-width:991px) {
                margin-top: 15px;
            }
        }

        .choose-current-label {
            color: $light-full_Black;
            font-family: 'Gilroy-Medium', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.12px;
            margin-top: 3px;
            display: block;
        }

        .input-group {
            position: relative;
            @include flex;

            @media (max-width:1366px) {
                height: 40px;
            }

            input {
                width: 100%;
                padding: 0;
            }

            button {
                width: 100%;
                color: $light-dark_grey;
                text-align: left;
                font-family: 'Gilroy-Medium', sans-serif;
                font-size: 14px;
                padding: 0;
                text-transform: capitalize;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .rowGroup {
            gap: 10px;

            @media (max-width:767px) {
                flex-direction: column;
                gap: 15px 0;
            }

            .css-b62m3t-container {
                margin-left: 2px;
            }

            .css-re3wqr-control {
                border-color: #e6e6e6;
            }

            .formGroup {
                width: 50%;

                @media (max-width:767px) {
                    width: 100%;
                }

                label {
                    color: $light-full_Black;
                }

                input {

                    &::placeholder {
                        color: $light-full_Black;
                        opacity: 1;
                    }

                    &::-ms-input-placeholder {
                        color: $light-full_Black;
                    }
                }
            }
        }

        .formGroup {
            .css-1jqq78o-placeholder {
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 14px;
                color: $light-full_Black;
            }

            .css-t3ipsp-control {
                height: 44px !important;
            }

            .css-t3ipsp-control:hover {
                border: none !important;
                border-color: transparent !important;
            }

            .country-option {
                span {
                    color: $light-full_Black;
                    font-family: "Gilroy-Medium", sans-serif;
                    font-size: 14px;


                }

                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 6px;

                    @media (max-width:1366px) {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }

        .current-location-txt {
            color: $light-amber;
            font-family: 'Gilroy-Medium', sans-serif;
            font-size: 12px;
            line-height: 22px;
            letter-spacing: -0.12px;
            text-decoration-line: underline;
            margin-top: 10px;
            cursor: pointer;
            text-transform: capitalize;
            padding: 0;
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .new-address-btn {
            @include flex;
            @include flex-align;
            @include flex-justify;
            border-radius: 6px;
            background: linear-gradient(to right, #afafaf 50%, transparent 0%) top repeat-x, linear-gradient(#afafaf 50%, transparent 0%) right repeat-y, linear-gradient(to right, #afafaf 50%, transparent 0%) bottom repeat-x, linear-gradient(#afafaf 50%, transparent 0%) left repeat-y;
            background-size: 8px 1px, 1px 8px;
            padding: 10px 7px;
            width: 100%;
            color: $light-full_Black;
            text-align: center;
            font-family: "Gilroy-Bold", sans-serif;
            font-size: 12px;
            text-transform: uppercase;
            margin-top: 20px;

            @media (max-width:1440px) {
                font-size: 11px;
            }
        }
    }

    .saved-locations {
        @include flex;
        @include flex-direction(column);
        gap: 10px;
        max-height: 250px;
        overflow: auto;
        padding-right: 5px;

        h4 {
            color: $light-full_Black;
            text-align: left;
            font-family: "Gilroy-Bold", sans-serif;
            font-size: 19px;
            margin-bottom: 5px;

            @media (max-width:1440px) {
                font-size: 15px;
            }

            @media (max-width:991px) {
                font-size: 14px;
            }
        }

        .item-locations {
            padding: 10px;
            @include flex;
            @include flex-direction(column);
            border-radius: 5px;
            border: 1px solid $light-light_grey;
            gap: 10px;

            @media (max-width:1440px) {
                gap: 5px;
            }

            &.isActive,
            &:hover {
                background: $light-amber_card_background;
            }

            .item-header {
                @include flex;
                @include flex-align(flex-start);
                @include flex-justify(space-between);
                width: 100%;
                gap: 10px;

                h6 {
                    color: $light-full_Black;
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.14px;
                    text-transform: none;
                    margin-bottom: 10px;

                    @media (max-width:1440px) {
                        font-size: 12px;
                    }
                }

                button {
                    display: flex;
                    padding: 5px 7px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 6px;
                    background: $light-full_Black;
                    color: $light-full_White;
                    text-align: center;
                    font-family: " Gilroy-Medium", sans-serif;
                    font-size: 12px;
                }
            }

            p {
                font-family: "Gilroy-medium", sans-serif;
                color: $light-dark_grey;
                text-transform: none;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    .recent-search {
        @include flex;
        @include flex-direction(column);
        gap: 10px;

        &.internal-place-search-results {
            margin-top: 30px;

            @media (max-width:1440px) {
                margin-top: 20px;
            }

            @media (max-width:991px) {
                margin-top: 15px;
            }
        }

        h4 {
            color: $light-full_Black;
            text-align: left;
            font-family: "Gilroy-Bold", sans-serif;
            font-size: 19px;
            margin-bottom: 5px;

            @media (max-width:1440px) {
                font-size: 15px;
            }

            @media (max-width:991px) {
                font-size: 14px;
            }
        }

        .recent-search-list {
            @include flex;
            @include flex-align(flex-start);
            @include flex-direction(column);
            gap: 14px;

            button {
                color: $light-dark_grey;
                text-align: left;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 12px;
                word-break: break-all;
                white-space: unset;
                cursor: pointer;
                padding: 0;
                text-transform: none !important;
            }
        }
    }

    .location-map {
        @include flex;
        @include flex-direction(column);
        @include flex-justify(flex-start);
        gap: 20px;

        @media (max-width:991px) {
            gap: 15px;
        }

        .form-block {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .rowGroup {
                margin: 0 -8px;
                gap: 0;

                @media (max-width:767px) {
                    flex-direction: column;
                }

                .formGroup {
                    padding: 0 8px;
                    flex: 1 1 50%;
                    width: 50%;

                    @media (max-width:767px) {
                        width: 100%;
                        flex: 1 1 100%;
                    }
                }
            }

            label {
                color: $light-full_Black;
            }
        }

        .delivery-address {
            @include flex;
            @include flex-justify(space-between);
            @include flex-align;
            gap: 10px;


            h6 {
                color: $light-full_Black;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 10px;
                text-transform: uppercase;

                span {
                    color: $light-chilly_red;
                    text-align: left;
                    font-family: "Gilroy-Medium", sans-serif;
                    text-transform: capitalize;
                    font-size: 16px;
                    display: block;
                    max-width: 185px;
                    @include para-line(1);
                    margin-top: 8px;

                    @media (max-width:767px) {
                        font-size: 14px;
                        max-width: 100%;
                    }
                }
            }

            .btn-change {
                border-radius: 6px;
                border: 1px solid $light-light_grey;
                padding: 6px 7px 5px 7px;
                @include flex;
                @include flex-align(center);
                @include flex-justify(center);
                color: $light-full_Black;
                text-align: center;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 12px;
                text-transform: uppercase;

                &:hover {
                    background: $light-light_grey
                }
            }
        }
    }

    .form-block {
        .save-list-wrap {
            margin-top: 15px;

            h4 {
                color: $light-full_Black;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 12px;
            }

            .save-list {
                @include flex;
                @include flex-align;
                flex-wrap: wrap;
                gap: 10px;
                margin-top: 10px;

                button {
                    border-radius: 8px;
                    border: 1px solid $light-light_grey;
                    background: $light-negative;
                    height: 44px;
                    text-align: center;
                    font-family: " Gilroy-Medium", sans-serif;
                    font-size: 12px;
                    padding: 7px 12px;
                    width: fit-content;

                    &.is-active,
                    &:hover {
                        background: $light-amber_card_background;
                        color: $light-full_Black;
                        border-color: transparent;
                    }
                }
            }
        }
    }

    .take-away-search {
        .item-locations {
            padding: 0px;
            gap: 0px;

            .item-store-details {
                @include flex;
                @include flex-direction(row);
                @include flex-justify(space-between);
                @include flex-align;
                padding: 10px;
                gap: 10px;
            }

            .schedule-time-wrap {
                padding-right: 0;
            }

            .left-block {
                @include flex;
                @include flex-align;
                gap: 16px;

                @media (max-width:1600px) {
                    gap: 10px;
                }

                .img-block {
                    flex: none;

                    img {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                    }
                }

                .txt-block {
                    @include flex-direction(column);
                    @include flex-justify;
                    @include flex-align(flex-start);
                    @include flex;

                    h6 {
                        color: $light-full_Black;
                        font-family: "Gilroy-ExtraBold", sans-serif;
                        font-size: 12px;
                        line-height: 22px;
                        letter-spacing: -0.12px;
                        text-align: left;
                    }

                    div {
                        @include flex-align(flex-start);
                        @include flex;
                        gap: 10px;

                        @media (max-width:767px) {
                            flex-direction: column;
                        }

                        span {
                            color: $light-full_Black;
                            font-family: "Gilroy-Regular", sans-serif;
                            font-size: 12px;
                            line-height: 20px;
                            letter-spacing: -0.12px;

                            &.outlets-time {
                                min-width: 60px;
                            }
                        }
                    }
                }
            }
        }
    }

    .schedule-time {
        h5 {
            color: $light-full_Black;
            font-family: "Gilroy-Bold", serif;
            font-size: 14px;

            line-height: 22px;
            letter-spacing: -0.14px;
        }

        .submint-btn {
            width: 200px;
            height: 44px;
            padding: 14px;
            @include flex;
            @include flex-justify;
            @include flex-align;
            gap: 10px;
            color: $light-full_White;
            text-align: center;
            font-family: "Gilroy-Bold", sans-serif;
            font-size: 12px;

            text-transform: uppercase;
            border-radius: 5px;
            background: $light-chilly_red;
            margin-top: 15px;
        }

        .time-wrap {
            @include flex;
            @include flex-align;
            @include flex-justify(space-between);

            @media (max-width:767px) {
                gap: 10px 0;
            }

            span {
                color: $light-full_Black;
                font-family: "Gilroy-Regular", sans-serif;
                font-size: 12px;

                line-height: 22px;
                letter-spacing: -0.12px;
            }

            .time-time {
                @include flex;
                @include flex-align;
                gap: 10px;


                select {
                    border-radius: 6px;
                    border: 1px solid $light-light_grey;
                    background: $light-negative;
                    padding: 11px;
                    width: 114px;
                    height: 42px;
                    color: $dark-full_Black;

                    @media (max-width:767px) {
                        padding: 6px;
                        width: 90px;
                    }
                }

                .btn-group {
                    @include flex;
                    @include flex-align;
                    @include flex-justify;
                    border-radius: 5px;
                    border: 1px solid $light-light_grey;

                    .btn {
                        @include flex;
                        @include flex-align;
                        @include flex-justify;
                        width: 38px;
                        height: 40px;
                        padding: 11px 12px;
                        gap: 10px;
                        transition: all 0.5s ease-in-out;
                        color: $light-full_Black;
                        text-align: center;
                        font-family: "Gilroy-Medium", sans-serif;
                        font-size: 10px;
                        line-height: 150%;
                        letter-spacing: 0.15px;

                        &.isAtive {
                            background: $light-chilly_red;
                            color: #fff;
                        }
                    }
                }
            }

        }

    }
}

.checkbox {
    position: relative;

    input {
        padding: 0;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        cursor: pointer;
        opacity: 0;
        position: absolute;
        z-index: 9;
    }

    span {
        position: relative;
        cursor: pointer;
        width: 18px;
        height: 18px;
        display: block;

        &:before {
            content: '';
            border-radius: 6px;
            border: 1px solid $light-board_grey;
            background: $light-negative;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            width: 18px;
            height: 18px;
            transition: all 0.5s ease-in-out;
        }
    }

    input:checked+span::before {
        background: $light-chilly_red;
        transition: all 0.5s ease-in-out;
    }

    input:checked+span:after {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 1px;
        background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/TickIcon.svg) no-repeat;
        margin: auto;
        background-position: center;
        background-size: 10px;
        width: 18px;
        height: 18px;
    }
}

.category-detail {
    .container {
        margin: auto;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        gap: 30px 0;

        @media (max-width:767px) {
            gap: 20px 0;
        }

        .col {
            width: calc(100% / 3);
            padding: 0 15px;

            @media (max-width:1024px) {
                width: calc(100% / 2);
            }

            @media (max-width:480px) {
                width: calc(100% / 1);
            }
        }
    }


    .category-detail-header {
        background: $light-amber_card_background;
        padding: 30px 0 15px;
        width: 100%;
        position: sticky;
        top: -1px;
        z-index: 90;

        @media (max-width:1440px) {
            padding: 15px;
        }

        .category-detail-carousel {
            .slick-slide {
                button {
                    padding: 0 10px;

                    @media (max-width:1199px) {
                        padding: 0 5px;
                    }
                }
            }

            .slick-prev {
                left: -50px;
            }

            .slick-next {
                right: -50px;
            }

            .category-detail-list {
                border-radius: 10px;
                border: 1px solid #F2DAB6;
                background: $light-shaded_white;
                display: flex !important;
                @include flex-align;
                padding: 15px;
                gap: 24px;
                transition: all .3s;

                @media (max-width:1600px) {
                    padding: 10px;
                    gap: 15px;
                }

                @media (max-width:1366px) {
                    padding: 7px;
                    gap: 10px;
                }

                &:hover,
                &.isActive {
                    background: $light-chilly_red;
                    transition: all .3s;

                    h4,
                    li {
                        color: $light-negative !important;
                    }

                    .price-txt {
                        color: $light-amber !important;
                    }
                }

                .offer-block {
                    display: flex;
                    width: 59px;
                    height: 59px;
                    min-width: 59px;
                    padding: 5px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 8px;
                    background: $light-amber;

                    @media (max-width:1600px) {
                        width: 50px;
                        height: 50px;
                        min-width: 50px;
                        border-radius: 6px;
                    }

                    @media (max-width:1366px) {
                        width: 40px;
                        height: 40px;
                        min-width: 40px;
                        border-radius: 6px;
                        padding: 3px;
                    }

                    img {
                        width: 47px;
                        height: 47px;
                        object-fit: cover;
                        border-radius: 8px;

                        @media (max-width:1600px) {
                            border-radius: 6px;
                        }

                        @media (max-width:1366px) {
                            width: 35px;
                            height: 35px;
                            border-radius: 6px;
                        }
                    }
                }

                .offer-block-txt {
                    flex: 1 1 100%;

                    h4 {
                        color: $light-full_Black;
                        font-family: "Gilroy-Bold", sans-serif;
                        font-size: 16px;
                        letter-spacing: -0.18px;
                        @include para-line(2);

                        @media (max-width:1366px) {
                            font-size: 14px;
                        }

                        @media (max-width:991px) {
                            font-size: 12px;
                        }
                    }

                    ul {
                        @include flex;
                        @include flex-align;
                        @include flex-justify(space-between);
                        margin-top: 6px;

                        li {
                            color: $light-full_Black;
                            font-family: "Gilroy-Regular", sans-serif;
                            font-size: 15px;
                            text-transform: capitalize;

                            &.price-txt {

                                color: $light-amber;
                                letter-spacing: -0.333px;
                            }
                        }
                    }
                }
            }
        }
    }

    .category-detail-list-wrap {
        display: flex;
        flex-direction: column;
        gap: 50px 0;

        @media (max-width:1199px) {
            gap: 30px;
        }

        @media (max-width:767px) {
            gap: 20px;
        }
    }

    .category-detail-banner-bg {
        background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/ShapeTop.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: left top;
        margin-top: -1px;
        min-height: 550px;
    }

    .category-detail-banner {
        margin-top: 50px;

        @media (max-width:1366px) {
            margin-top: 30px;
        }

        .title-block {
            padding: 50px 0;

            @media (max-width:1440px) {
                padding: 30px 0;
            }

            @media (max-width:1199px) {
                padding: 20px 0;
            }

            @media (max-width:767px) {
                padding: 15px 0;
            }

            h4 {
                color: $light-full_Black;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 24px;
                line-height: 38px;

                @media (max-width:1440px) {
                    font-size: 20px;
                    line-height: 26px;
                }

                @media (max-width:991px) {
                    font-size: 18px;
                    line-height: 20px;
                }
            }

            p {
                color: $light-full_Black;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 16px;
                margin-top: 10px;
                line-height: 23px;

                @media (max-width:767px) {
                    font-size: 14px;
                    max-width: 520px;
                }
            }
        }

        .category-banner {
            display: flex;
            position: relative;
            height: 332px;
            overflow: hidden;
            border-radius: 20px;

            @media (max-width: 1440px) {
                border-radius: 12px;
            }

            @media (max-width:1366px) {
                border-radius: 8px;
                height: 280px;
            }

            @media (max-width:991px) {
                height: 250px;
            }

            @media (max-width:767px) {
                height: 190px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .txt-block {
            position: absolute;
            z-index: 9;
            top: 0;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            padding: 30px;


            @media (max-width:991px) {
                padding: 20px;
            }

            @media (max-width:767px) {
                padding: 15px;
            }



            h3 {
                color: $light-negative;
                font-family: "Gilroy-ExtraBold", sans-serif;
                font-size: 42px;
                font-style: normal;
                line-height: 47px;
                letter-spacing: -0.42px;
                width: 245px;

                @media (max-width:1600px) {
                    font-size: 34px;
                    line-height: 36px;
                }

                @media (max-width:1366px) {
                    font-size: 28px;
                }

                @media (max-width:1199px) {
                    font-size: 24px;
                    line-height: 28px;
                    width: 170px;
                }

                @media (max-width:991px) {
                    font-size: 22px;
                    line-height: 22px;
                }

                span {
                    display: block;
                    color: $light-negative;
                    font-family: "Gilroy-Medium", sans-serif;
                    font-size: 22px;
                    line-height: 20px;
                    letter-spacing: -0.22px;
                    margin-top: 24px;

                    @media (max-width:1600px) {
                        margin-top: 16px;
                        font-size: 18px;
                    }

                    @media (max-width:1366px) {
                        margin-top: 10px;
                        font-size: 16px;
                    }
                }
            }

            .btn-group {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: auto;
                margin-bottom: 0;
                width: 100%;

                .cart-btn {
                    display: flex;
                    width: 192px;
                    padding: 14px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 12px;
                    background: $light-chilly_red;
                    color: $light-negative;
                    text-align: center;
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 12px;
                    text-transform: uppercase;

                    @media (max-width:767px) {
                        width: 150px;
                    }
                }

                .like-btn {
                    display: flex;
                    width: 44px;
                    padding: 14px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 12px;
                    background: $light-shaded_white;
                    transition: all 0.5s ease-in-out;

                    &.isactive,
                    &:hover {
                        svg {
                            path {
                                fill: $light-negative;
                            }
                        }
                    }
                }
            }
        }
    }

    .category-detail-card-wrap {
        .category-detail-card {
            .img-block {
                border-radius: 20px;

                @media (max-width: 1440px) {
                    border-radius: 12px;
                }

                @media (max-width:1366px) {
                    border-radius: 8px;
                }

                .pro-btn {
                    padding: 0;
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .title-block {
            h4 {
                color: #000;
                text-align: center;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 38px;
                width: fit-content;
                margin: auto;
                margin-top: 55px;
                margin-bottom: 60px;
                position: relative;

                @media (max-width:1600px) {
                    margin-top: 35px;
                    margin-bottom: 40px;
                    font-size: 32px;
                }

                @media (max-width:1440px) {
                    margin-top: 25px;
                    margin-bottom: 30px;
                    font-size: 26px;
                }

                @media (max-width:1366px) {
                    font-size: 23px;
                }

                @media (max-width:1024px) {
                    font-size: 18px;
                }

                @media (max-width:991px) {
                    margin-top: 15px;
                    margin-bottom: 20px;
                }


                &::after {
                    content: '';
                    width: 85%;
                    height: 1px;
                    background: $light-amber;
                    display: block;
                    margin: auto;
                    margin-top: 16px;

                    @media (max-width:1600px) {
                        margin-top: 10px;
                        width: 140px;
                    }

                    @media (max-width:1440px) {
                        margin-top: 8px;
                        width: 110px;
                    }

                    @media (max-width:991) {
                        margin-top: 8px;
                        width: 80px;
                    }
                }
            }
        }
    }
}

.category-detail-card {
    @include flex;
    @include flex-direction(column);
    gap: 20px;
    height: 100%;


    @media (max-width:1600px) {
        gap: 10px;
    }

    .img-block {
        position: relative;
        overflow: hidden;
        background: #F8F2E9;
        border-radius: 4px;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: middle;
        }

        .like-btn-block {
            position: absolute;
            top: 30px;
            width: 100%;
            left: 0;
            right: 0;
            margin: auto;
            @include flex;
            @include flex-align;
            @include flex-justify(space-between);

            @media (max-width:991px) {
                top: 20px;
            }

            @media (max-width:767px) {
                top: 15px;
            }

            h6 {
                width: 46px;
                height: 46px;
                clip-path: polygon(100% 50%, 84.78% 53.92%, 98.75% 61.13%, 83.04% 61.56%, 95.05% 71.69%, 79.64% 68.62%, 89.09% 81.17%, 74.75% 74.75%, 81.17% 89.09%, 68.62% 79.64%, 71.69% 95.05%, 61.56% 83.04%, 61.13% 98.75%, 53.92% 84.78%, 50% 100%, 46.08% 84.78%, 38.87% 98.75%, 38.44% 83.04%, 28.31% 95.05%, 31.38% 79.64%, 18.83% 89.09%, 25.25% 74.75%, 10.91% 81.17%, 20.36% 68.62%, 4.95% 71.69%, 16.96% 61.56%, 1.25% 61.13%, 15.22% 53.92%, 0% 50%, 15.22% 46.08%, 1.25% 38.87%, 16.96% 38.44%, 4.95% 28.31%, 20.36% 31.38%, 10.91% 18.83%, 25.25% 25.25%, 18.83% 10.91%, 31.38% 20.36%, 28.31% 4.95%, 38.44% 16.96%, 38.87% 1.25%, 46.08% 15.22%, 50% 0%, 53.92% 15.22%, 61.13% 1.25%, 61.56% 16.96%, 71.69% 4.95%, 68.62% 20.36%, 81.17% 10.91%, 74.75% 25.25%, 89.09% 18.83%, 79.64% 31.38%, 95.05% 28.31%, 83.04% 38.44%, 98.75% 38.87%, 84.78% 46.08%);
                color: $light-negative;
                text-align: center;
                font-family: "Gilroy-Regular", sans-serif;
                font-size: 10px;

                line-height: 10px;
                letter-spacing: -0.1px;
                background: $light-amber;
                @include flex;
                @include flex-align;
                @include flex-justify;
            }

            .like-btn {
                border-radius: 8px;
                background: #f3e6d3;
                height: 32px;
                width: 32px;
                @include flex;
                @include flex-align;
                @include flex-justify;
                padding: 0 !important;
                position: absolute;
                right: 30px;
                top: 0;
                transition: all 0.5s ease-in-out;

                @media (max-width:991px) {
                    right: 20px;
                }

                @media (max-width:767px) {
                    right: 15px;
                }

                svg {
                    path {
                        stroke: $light-chilly_red;
                        fill: none;
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: $light-chilly_red;

                            @media (max-width:767px) {
                                fill: none;
                            }
                        }
                    }
                }

                &.isActive {
                    svg {
                        path {
                            fill: $light-chilly_red;
                        }
                    }
                }
            }
        }

        .add-btn {
            color: $light-negative;
            text-align: center;
            font-family: "Gilroy-Bold", sans-serif;
            font-size: 16px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            width: 114px;
            height: 48px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -80px;
            margin: auto;
            border-radius: 12px;
            background: $light-chilly_red;
            transition: all 0.3s ease-in-out;

            @media (max-width:1600px) {
                height: 44px;
            }

            @media (max-width:1366px) {
                font-size: 14px;
                height: 40px;
            }

            &:hover {
                background: $light-chilly_red_dark;
            }

            @media (max-width:767px) {
                font-size: 14px;
            }
        }

        &:hover {
            .add-btn {
                bottom: 30px;
            }
        }
    }

    .txt-block {
        @include flex;
        @include flex-direction(column);
        gap: 20px;

        @media (max-width:1600px) {
            gap: 10px;
        }

        @media (max-width:767px) {
            gap: 5px;
        }

        .category-name {
            color: $light-full_Black;
            font-family: "Gilroy-Bold", sans-serif;
            font-size: 22px;
            line-height: 33px;
            letter-spacing: -0.22px;
            padding: 0;
            text-transform: unset;

            @media (max-width:1600px) {
                font-size: 18px;
            }

            @media (max-width:1366px) {
                font-size: 16px;
                line-height: 28px;
            }

            @media (max-width:991px) {
                font-size: 14px;
                line-height: 22px;
            }
        }

        .veg-block {
            @include flex;
            @include flex-justify(space-between);
            @include flex-align;

            span {
                color: $light-dark_grey;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 16px;
                line-height: 26px;

                @media (max-width:767px) {
                    font-size: 14px;
                }
            }

            p {
                color: $light-full_Black;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 19px;
                line-height: 14px;
                letter-spacing: -0.19px;


                @media (max-width:1600px) {
                    font-size: 17px;
                }

                @media (max-width:991px) {
                    font-size: 14px;
                }

                del {
                    color: $light-amber;
                    font-family: "Gilroy-Regular", sans-serif;
                    font-size: 16px;
                    line-height: 19.2px;
                    letter-spacing: -0.16px;
                    text-decoration-line: strikethrough;
                    margin-right: 10px;
                }

            }
        }
    }
}

.order-type-modal {
    @include flex-justify;

    .chicking-modal-wraper {
        max-width: 513px;
        padding: 40px;

        @media (max-width:1600px) {
            padding: 30px;
        }

        @media (max-width:1366px) {
            padding: 20px;
        }

        @media (max-width:991px) {
            padding: 15px;
        }

        .chicking-modal-header {
            h4 {
                color: $light-full_Black !important;
                font-size: 20px !important;
                line-height: 22px !important;

                @media (max-width: 767px) {
                    font-size: 18px;
                }
            }

            p {
                color: $light-full_Black;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.16px;

                @media (max-width:767px) {
                    font-size: 14px;
                }
            }

            span {
                color: $light-chilly_red;
            }
        }

        .chicking-modal-body {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .order-type-list {
                @include flex;
                @include flex-justify;
                flex-wrap: wrap;
                gap: 5px;

                li {
                    width: calc((100% / 3) - 5px);
                    border-radius: 5px;
                    border: 1px solid #cfcfcf;
                    background: $light-negative;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;

                    @media (max-width:767px) {
                        width: calc((100% / 2) - 5px);
                    }

                    @media (max-width:480px) {
                        width: calc((100% / 1) - 5px);
                    }

                    svg {
                        path {
                            transition: all 0.3s ease-in-out;
                        }
                    }

                    &.isActive,
                    &:hover {
                        border: 1px solid $light-chilly_red;

                        svg {
                            path {
                                fill: $light-chilly_red;
                            }
                        }
                    }
                }

                figure {
                    @include flex;
                    @include flex-direction(column);
                    padding: 20px 12px;
                    gap: 20px 0;

                    img {
                        max-width: 66px;
                        max-height: 58px;
                    }

                    svg,
                    img {
                        display: block;
                        margin: auto;
                    }

                    figcaption {
                        display: block;
                        color: $light-full_Black;
                        text-align: center;
                        font-family: "Gilroy-Bold", sans-serif;
                        font-size: 14px;
                        letter-spacing: -0.16px;
                        text-transform: uppercase;
                    }
                }

            }

            p {
                color: #7D7A7A;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 14px;
                line-height: 23px;

                @media (max-width:767px) {
                    font-size: 12px;
                }
            }
        }

        .chicking-modal-footer {
            .btn-group {
                justify-content: flex-start;
            }
        }

        .submint-btn {
            margin-left: auto !important;
            width: 157px !important;
            height: 48px !important;
            font-family: "Gilroy-Bold", sans-serif;
            font-size: 16px !important;
            text-transform: uppercase;
            padding: 10px !important;
        }
    }
}

.submint-btn {
    width: 70px;
    padding: 14px 43px;
    display: block;
    margin: auto;
    margin-top: 20px;
}

.quantitywrap {
    @include flex;
    @include flex-align;
    border-radius: 8px;
    border: 1px solid $light-light_grey;
    width: 100px;
    height: 30px;

    .btn {
        @include flex;
        @include flex-align;
        @include flex-justify;
        padding: 0;
        width: 70px;
        height: 100%;
    }

    .formControl {
        border: none !important;
        border-radius: 0px !important;
        width: 40px !important;
        padding: 0 5px !important;
        height: 30px !important;
        background: transparent !important;
        text-align: center !important;
        font-family: 'Gilroy-Bold', sans-serif !important;

        &:focus {
            outline: none !important;
        }
    }
}

.graphic-block-wrap {
    @include flex;
    @include flex-justify(space-between);
    @include flex-align;
    gap: 15px;

    .graphic-block {
        position: relative;
        border: 2px solid $light-chilly_red;
        background: $light-negative;
        width: 17px;
        height: 17px;
        display: block;

        &::before {
            content: '';
            background: $light-chilly_red;
            width: 9px;
            height: 9px;
            display: block;
            border-radius: 100px;
            position: absolute;
            inset: 0;
            margin: auto;
            z-index: 9;
        }
    }

    &.veg {
        .graphic-block {
            border: 2px solid $light-green !important;
            background: $light-negative !important;

            &::before {
                content: '';
                background: $light-green !important;
            }
        }
    }
}

.order-details-wrap {
    @include flex;
    @include flex-justify(space-between);
    align-items: center;

    @media (max-width:767px) {
        gap: 10px;
    }

    .order-list {
        width: calc(100% - 88px);

        h4 {
            color: $light-full_Black;
            font-family: "Gilroy-Medium", sans-serif;
            font-size: 14px;

            line-height: 22px;
            letter-spacing: -0.14px;
            margin-bottom: 11px;
        }

        .quantity-wrap {
            margin-top: 4px;
            @include flex;
            @include flex-align;
            gap: 0 15px;

            h5 {
                color: $light-full_Black;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 12px;

                line-height: 20px;
                letter-spacing: -0.12px;
            }
        }
    }

    .order-list-img {
        width: 88px;
        height: 88px;
        position: relative;

        img {
            width: 88px;
            height: 88px;
            object-fit: cover;
            border-radius: 5px;
        }

        .graphic-block-wrap {
            position: absolute;
            bottom: 9px;
            left: 3px;
        }

        .like-wrap {
            position: absolute;
            top: 9px;
            right: 3px;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.30);
            width: 32px;
            height: 32px;
            @include flex;
            @include flex-align;
            @include flex-justify;
            cursor: pointer;
        }
    }
}

.choice-chicken-wrap {
    margin-top: 20px;

    h4 {
        color: $light-full_Black;
        text-align: left;
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 19px;

        margin-bottom: 15px;
    }

    .choice-list-wrap {
        @include flex;
        @include flex-justify;
        @include flex-direction(column);
        gap: 15px;

        .list {
            @include flex;
            @include flex-align;
            gap: 8px;

            @media (max-width:767px) {
                @include flex-direction(column);
                @include flex-align(flex-start);

            }

            .img-block {
                img {
                    max-width: 51px;
                    height: 51px;
                    border-radius: 5px;
                }
            }

            .txt-block {
                h5 {
                    color: $light-full_Black;
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 12px;
                    text-transform: capitalize;
                }

                span {
                    color: $light-dark_grey;
                    font-family: "Gilroy-Regular", sans-serif;
                    font-size: 12px;
                    letter-spacing: -0.333px;
                    text-transform: capitalize;

                    @media (max-width:767px) {
                        margin-top: 5px;
                        display: block;
                    }
                }
            }
        }
    }
}

.offer-details-wrap {
    @include flex;
    @include flex-direction(column);
    gap: 20px;

    @media (max-width:991px) {
        gap: 15px;
    }

    .formControl {
        padding: 0 !important;
    }

    .input-group {
        padding-right: 7px;
    }

    .apy-btn {
        border-radius: 5px;
        background: $light-full_Black;
        padding: 7px 12px;
        @include flex;
        @include flex-justify;
        @include flex-align;
        color: $light-negative;
        text-align: center;
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 12px;

        text-transform: uppercase;
    }
}

.offer-list-wrap {
    @include flex;
    @include flex-direction(column);
    gap: 20px;

    @media (max-width:991px) {
        gap: 20px;
    }

    .offer-list {
        @include flex;
        @include flex-direction(column);
        gap: 10px;

        @media (max-width:767px) {
            gap: 5px;
        }

        .list-block {
            @include flex;
            @include flex-justify(space-between);
            align-items: flex-start;
            gap: 11px;

            @media (max-width:767px) {

                gap: 5px;
            }

            .offer-list-txt {
                h6 {
                    color: $light-full_Black;
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 14px;

                    line-height: 22px;
                    letter-spacing: -0.14px;
                    margin-bottom: 3px;
                }

                h4 {
                    display: block;
                    font-size: 12px;
                    margin-top: 3px;
                    color: $light-green;
                    font-family: "Gilroy-Bold", sans-serif;
                }

                a,
                p {
                    font-family: "Gilroy-Medium", sans-serif;
                    font-size: 12px;

                    line-height: 18px;
                    text-align: left;
                }

                p {
                    color: $light-full_Black;

                }

                button {
                    color: $light-chilly_red;
                    text-decoration-line: underline;
                    display: block;
                    margin-top: 3px;
                    padding: 0;
                }
            }

            .offer-block {
                width: 130px;
                height: auto;
                border-radius: 5px;
                background: $light-chilly_red;
                @include flex;
                @include flex-align;
                @include flex-justify;
                @include flex-direction(column);
                padding: 20px;
                flex: none;

                @media (max-width:767px) {
                    width: 90px;
                    height: auto;
                    padding: 10px;
                }

                @media (max-width:380px) {
                    padding: 5px;
                }

                h4 {
                    color: $light-negative;
                    text-align: center;
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 24px;
                    line-height: 20px;
                    margin-bottom: 10px;

                    @media (max-width:1199px) {
                        font-size: 20px;
                    }

                    @media (max-width:767px) {
                        font-size: 18px;
                    }
                }

                span {
                    color: $light-negative;
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;

                    @media (max-width:1366px) {
                        font-size: 14px;
                    }

                    @media (max-width:767px) {
                        font-size: 12px;
                    }
                }

                svg {
                    margin-top: 5px;
                    max-width: 50px;
                    max-height: 50px;

                    @media (max-width:767px) {
                        max-width: 30px;
                        max-height: 30px;
                    }
                }
            }
        }

        p {
            color: $light-dark_grey;
            font-family: "Gilroy-Medium", sans-serif;
            font-size: 11px;

            line-height: 18px;
        }

        &.inactive {
            .list-block {
                .offer-list-txt {
                    button {
                        color: $light-light_grey;
                    }
                }
            }

            .offer-block {
                background: $light-light_grey;
            }
        }
    }
}

.cart-section {
    display: flex;
    justify-content: center;
    background:$light-negative url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images//ShapeTop.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;

    .rowGroup {
        padding-top: 50px;

        @media (max-width:991px) {
            flex-wrap: wrap;
            padding-top: 20px;

            .width66,
            .width33 {
                width: 100%;
            }
        }
    }

    .cartitems-title {
        margin-bottom: 40px;

        @media (max-width:1366px) {
            font-size: 20px !important;
            margin-bottom: 20px;
        }

        @media (max-width:1280px) {
            margin-bottom: 15px;
            font-size: 20px !important;
        }

        @media (max-width:1024px) {
            font-size: 18px !important;
            margin-bottom: 10px;
        }
    }

    .cart-wrap {
        @include flex;
        @include flex-direction(column);
        gap: 20px;

        @media (max-width:1600px) {
            gap: 15px;
        }
    }

    .addmoreitems {
        gap: 8px !important;
    }
}

.ordertracking {
    .sub-title {
        color: $light-full_Black;
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.14px;
    }

    .cartitems-title {
        margin-bottom: 0px !important;
    }

    .cartitems-wrap {
        @include flex;
        @include flex-direction(column);
        gap: 20px;

        @media (max-width:1600px) {
            gap: 15px;
        }
    }

    .remarks-block {
        p {
            color: $light-full_Black;
            font-family: "Gilroy-Medium", sans-serif;
            font-size: 12px;

            max-width: 338px;
            margin-top: 10px;
        }
    }

    .payment-mode {
        border-top: 0.5px dashed $light-board_grey;
        padding-top: 15px;
    }

    .checkout-btn {
        width: fit-content !important;
    }
}

.bite-btn {
    border-radius: 5px;
    background: $light-amber_card_background;
    display: flex;
    padding: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-transform: unset;
    color: $light-full_Black;
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 12px;

    @media (max-width:767px) {
        gap: 10px;
    }

    &.profile-bite-btn {
        background: $light-chilly_red;
        color: $light-negative;
        color: #F5C4C7;
        font-family: "Gilroy-Medium", sans-serif;
        gap: 8px;
        border-radius: 6px;

        span {
            color: $light-negative;
            font-family: "Gilroy-Medium", sans-serif;
        }
    }

    span {
        color: $light-amber;
    }
}

.cartitems-wrap {
    border-radius: 5px;
    border: 1px solid #E3E3E3;
    background: $light-negative;
    padding: 30px;

    @media (max-width:1440px) {
        padding: 20px;
    }

    @media (max-width:1024px) {
        padding: 15px;
    }

    .cartitems-title {
        color: $light-full_Black;
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 28px;
        letter-spacing: -0.28px;

        @media (max-width:1440px) {
            font-size: 24px;
            margin-bottom: 20px;
        }

        @media (max-width:1280px) {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }

}

.cartitems-list {
    @include flex;
    @include flex-direction(column);
    gap: 20px;

    @media (max-width:991px) {
        gap: 15px;
    }

    .items-img-btn {
        padding: 0 !important;
    }

    .cartitem {
        @include flex;
        @include flex-align;
        gap: 16px;
        padding: 0;
        border-radius: 0;

        @media (max-width:520px) {
            @include flex-direction(row);
        }

        .img-block {
            width: 88px;
            min-width: 88px;
            height: 88px;

            @media (max-width:520px) {
                width: 65px;
                min-width: 65px;
                height: 65px;
            }


            img {
                border-radius: 5px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }


    }
}

.cartitem {
    @include flex;
    @include flex-align;
    gap: 16px;

    @media (max-width: 520px) {
        @include flex-direction(column);
    }

    .img-block {
        width: 88px;
        min-width: 88px;
        height: 88px;

        img {
            border-radius: 5px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .link-wrap {
        @include flex;
        @include flex-justify(flex-start);
        @include flex-align(center);
        gap: 10px;

        .cart-link {
            color: $light-chilly_red;
            text-align: center;
            font-family: 'Gilroy-Bold', sans-serif;
            font-size: 12px;
            text-decoration-line: underline;
            padding: 0;
            text-transform: capitalize;
            letter-spacing: 0;
        }

        svg {
            cursor: pointer;
        }
    }

    .cart-details {
        width: 100%;
        @include flex;
        @include flex-direction(column);
        gap: 11px;

        @media (max-width:767px) {
            gap: 3px;
        }

        .title-box {
            @include flex;
            @include flex-justify(space-between);
            @include flex-align;

            h4 {
                color: $light-full_Black;
                font-family: 'Gilroy-Bold', sans-serif;
                font-size: 14px;
                word-break: break-all;
                padding-right: 10px;
                line-height: 22px;
                letter-spacing: -0.14px;
            }

            .action-btn {
                display: flex;
                gap: 10px;

                button {
                    border-radius: 7px;
                    border: 1px solid $light-light_grey;
                    padding: 0;
                    width: 26px;
                    height: 26px;
                    @include flex;
                    @include flex-align;
                    @include flex-justify;

                    &.delete-btn {
                        svg {
                            width: 18px;
                            flex: none;
                            height: 18px;
                        }
                    }

                    &:hover,
                    &.isActive {
                        svg {
                            path {
                                stroke: $light-chilly_red;
                            }
                        }
                    }
                }
            }
        }

        .quantity-details {
            @include flex;
            @include flex-justify(space-between);

            span {
                color: $light-full_Black;
                font-family: 'Gilroy-Bold', sans-serif;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: -0.12px;
            }
        }

        .items {
            cursor: unset;

            button {
                padding: 0;
                border: none !important;
            }
        }
    }


}

.addmoreitems-wrap {
    .addmoreitems {
        height: 100%;
        border-radius: 5px;
        background: $light-amber_card_background;
        padding: 8px;
        @include flex;
        @include flex-align;
        gap: 16px;

        @media (max-width: 1600px) {
            gap: 10px;
        }

        .addmoreitems-img {
            min-width: 75px;
            max-width: 75px;
            height: 75px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 3px;
            }
        }

        .addmoreitems-details {
            @include flex;
            @include flex-direction(column);
            gap: 10px;

            .product-name {
                @include flex;
                @include flex-align;
                gap: 6px;
            }

            h6 {
                color: $light-full_Black;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 12px;
                text-transform: capitalize;
                cursor: pointer; // Indicates interactivity for tooltip
                display: -webkit-box;
                max-width: 100%;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                word-break: break-all;
            }

            p {
                color: $light-dark_grey;
                font-family: "Gilroy-Regular", sans-serif;
                font-size: 12px;
                letter-spacing: -0.333px;
                text-transform: capitalize;
                display: block;
            }

            span {
                color: $light-dark_grey;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 12px;

                letter-spacing: -0.333px;
                text-transform: capitalize;
            }

            button {
                padding: 3px 9px;
                border-radius: 3px;
                background: $light-chilly_red;
                color: $light-negative;
                text-align: center;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 12px;
                text-transform: uppercase;
                margin: auto;
                margin-left: 0;
            }
        }
    }
}

.tab-container {
    .tabs {
        @include flex;
        @include flex-align;
        justify-content: space-between;
        gap: 10px;
        overflow: auto;

        @media (max-width: 767px) {
            overflow: auto;
        }

        .tab {
            border-radius: 6px;
            border: 1px solid $light-light_grey;
            padding: 13px 10px;
            min-width: calc((100% / 3) - 10px);
            flex: 1 1;
            cursor: pointer;
            transition: all 0.5s ease-in-out;

            @media (max-width: 1366px) {
                padding: 10px 6px;
            }

            @media (max-width: 767px) {
                min-width: calc((100% / 2) - 10px);
                white-space: nowrap;
            }

            &:hover {
                background: $light-amber;

                h4 {
                    color: $light-negative;
                }
            }

            &.isactive {
                background: $light-amber;

                h4 {
                    color: $light-negative;
                }
            }

            h4 {
                color: $light-dark_grey;
                text-align: center;
                font-family: 'Gilroy-Bold', sans-serif;
                font-size: 12px;

                text-transform: uppercase;
            }
        }
    }

    .tab-contents {
        margin-top: 20px;
    }
}

.billing-details-wrap {
    .radio-box {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        svg {
            margin: 0 8px;
            position: relative;
            top: 3px;

            @media (max-width:1280px) {
                margin: 0 4px;
                width: 16px;
            }
        }

        span:before {
            @media (max-width:1280px) {
                width: 15px;
                height: 15px;
                border-radius: 3px;
            }
        }

        input:checked+span:after {
            @media (max-width:1280px) {
                left: -1px;
            }
        }

        p {
            color: $light-full_Black;
            font-family: "Gilroy-Medium", sans-serif;
            font-size: 12px;
            flex: none;
            line-height: 0;

            small {
                color: $light-chilly_red;
                font-family: "Gilroy-Medium", sans-serif;
            }
        }
    }

    span {
        color: $light-dark_grey !important;
    }
}

.cart-billing-details {
    ul {
        @include flex;
        @include flex-direction(column);
        gap: 10px;

        li {
            @include flex;
            @include flex-align;
            @include flex-justify(space-between);

            &.border {
                border-top: 1px solid #E6E6E6;
                margin: 20px 0 0 0;
                padding: 20px 0 0 0;

                @media (max-width:1440px) {
                    margin: 10px 0 0 0;
                    padding: 10px 0 0 0;
                }
            }

            span {
                color: $light-full_Black;
                font-family: 'Gilroy-Medium', sans-serif;
                font-size: 12px;
                @include flex;
                @include flex-align;
                gap: 6px;

                &.free {
                    color: $light-amber !important;
                }

                &.promo-txt {
                    color: $light-amber !important;
                    font-family: "Gilroy-Bold", sans-serif;
                }

                small {
                    color: #9796A1;
                    font-family: "Gilroy-Medium", sans-serif;
                    font-size: 12px;


                }
            }

            &.total-txt {
                color: $light-full_Black;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 12px;

                span {
                    color: $light-full_Black !important;
                    font-family: "Gilroy-Bold", sans-serif;
                }
            }

        }
    }

    .checkout-btn {
        @include flex;
        @include flex-align;
        justify-content: center;
        gap: 0 11px;
        width: 243px;
        padding: 16px 21px;
        border-radius: 12px;
        background: $light-chilly_red;
        color: $light-negative;
        text-align: left;
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        margin-top: 40px;

        @media (max-width:1440px) {
            padding: 12px 16px;
            margin-top: 30px;
            font-size: 12px;
            border-radius: 10px;
        }

        @media (max-width:1280px) {
            font-size: 12px;
            padding: 10px 12px;
            width: 100% !important;
            margin-top: 15px;
            border-radius: 8px;
        }

        @media (max-width:767px) {
            margin-top: 15px;
            font-size: 11px;
        }

        svg {
            width: 16px;
            height: 16px;

            path {
                stroke: #fff;
            }
        }


    }
}


.coupon-txt {
    @include flex;
    @include flex-justify(space-between);
    flex-direction: column;
    padding: 17px 0;
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    margin-bottom: 20px;
    gap: 10px;

    div {
        align-items: center;
        @include flex;
        @include flex-justify(space-between);
    }

    button {
        padding: 0;
    }

    h5 {
        color: $light-full_Black;
        text-align: left;
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 12px;
        text-transform: uppercase;
    }

    svg {
        max-width: 8px;
    }

    .coupon-info {
        button {
            border-radius: 7px;
            border: 1px solid $light-light_grey;
            padding: 5px;
            width: 26px;
            height: 26px;
            @include flex;
            @include flex-align;
            @include flex-justify;

            svg {
                max-width: 18px;
            }

            &:hover,
            &.isActive {
                svg {
                    path {
                        stroke: $light-chilly_red;
                    }
                }
            }

        }

        p {
            color: $light-amber !important;
            font-family: Gilroy-Bold, sans-serif;
            font-size: 12px;
        }
    }

}

.order-id {
    ul {
        @include flex;
        @include flex-direction(column);
        gap: 10px;

        li {
            @include flex;
            @include flex-justify(space-between);
            gap: 10px;

            @media (max-width:767px) {
                gap: 3px;
                flex-direction: column;
                align-items: flex-start;
            }

            button {
                text-transform: unset;
                border-radius: 8px;
                padding: 8px 13px;
                text-align: center;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 12px;
                font-weight: 400;

                &.default_btn {
                    background-color: #FAF9F7;
                    color: $light-full_Black;
                    border: solid 1px #E6E6E6;
                    text-transform: unset;
                }

                &.primary_btn {
                    margin: auto;
                    margin-right: 0;
                }
            }

            span {
                color: $light-full_Black;
                text-align: center;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 12px;


                &.statusTime {
                    color: $light-amber;
                }

                &.statusSuccess {
                    color: $light-green;
                }

                &.statusPending {
                    color: $light-amber;
                }

                &.statusCancel {
                    color: $light-chilly_red;
                }
            }

            &:last-child {
                span {
                    color: $light-amber;
                }
            }
        }
    }
}

.track-order-wrap {
    @include flex;
    @include flex-direction(column);
    gap: 15px;

    .order-tracking-wrap {
        @include flex;
        @include flex-direction(column);
        gap: 15px;

        ul {
            @include flex;
            @include flex-direction(column);
            gap: 32px;

            @media (max-width:1199px) {
                gap: 25px;
            }

            @media (max-width:991px) {
                gap: 20px;
            }

            li {
                @include flex;
                @include flex-align;
                @include flex-justify(space-between);


                span {
                    color: $light-full_Black;
                    font-family: "Gilroy-Medium", sans-serif;
                    font-size: 12px;

                    @media (max-width:640px) {
                        font-size: 11px;
                    }
                }

                .progtrckr-wrap {
                    @include flex;
                    @include flex-align;
                    gap: 11px;

                    .progress-status {
                        width: 16px;
                        height: 16px;
                        border-radius: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;

                        svg {
                            position: relative;
                            z-index: 2;
                        }

                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 1px;
                            height: 50px;
                            left: 0;
                            top: 0;
                            z-index: 1;
                            right: 0;
                            margin: auto;

                        }

                        &.progress-done {
                            background: $light-green;

                            &::after {
                                background: $light-green;

                            }
                        }

                        &.progress-pending {
                            background: #7f7f7f;

                            &::after {
                                background: #7f7f7f;
                            }
                        }

                        &.progress-cancelled {
                            background: $light-chilly_red;

                            &::after {
                                background: $light-chilly_red;
                            }
                        }
                    }
                }

                &:last-child {
                    .progress-status {
                        &::after {
                            display: none;
                        }
                    }

                    .progtrckr-done {
                        &::after {
                            display: none;
                        }
                    }
                }

                .date-block {
                    @include flex;
                    @include flex-align;
                    gap: 20px;

                    @media (max-width:991px) {
                        gap: 15px;
                    }

                    @media (max-width:767px) {
                        gap: 10px;
                    }

                    span {
                        color: $light-amber;
                    }

                    .date-txt {
                        color: $light-full_Black;
                    }
                }
            }
        }
    }
}

.delivery-block {
    @include flex;
    @include flex-align;
    gap: 20px;
    padding: 14px;

    @media (max-width:991px) {
        gap: 15px;
    }

    @media (max-width:767px) {
        padding: 0;
    }

    svg {
        min-width: 23px;
    }

    span {
        color: $light-full_Black;
        font-family: "Gilroy-Medium", sans-serif;
        font-size: 12px;

    }
}

.order-address-wrap {
    .order-address {
        ul {
            @include flex;
            @include flex-direction(column);
            gap: 15px;
            padding: 14px 0px;

            li {
                @include flex;
                @include flex-align;
                gap: 20px;
                padding-bottom: 10px;
                border-bottom: 1px solid $light-board_grey;

                @media (max-width:1600px) {
                    gap: 15px;
                }

                @media (max-width:991px) {
                    gap: 10px;
                }

                svg {
                    flex: none;
                }

                div {
                    span {
                        color: $light-full_Black;
                        font-family: "Gilroy-Medium", sans-serif;
                        font-size: 12px;

                        display: block;
                        line-height: 18px;
                    }
                }

                button {
                    margin: auto;
                    margin-right: 0;
                    padding: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                    border: none;
                }
            }
        }
    }
}

.order-item-wrap {
    .order-item {
        position: relative;
        padding: 14px;

        @media (max-width:767px) {
            padding: 0;
        }

        .order-count {
            span {
                color: $light-full_Black;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 12px;

                border-bottom: 0.5px solid $light-board_grey;
                padding-bottom: 10px;
                display: block;
                margin-bottom: 15px;
            }
        }
    }
}

.cartitems-remarks {
    h4 {
        color: $light-full_Black;
        font-family: "Gilroy-Medium", sans-serif;
        font-size: 12px;

        margin-bottom: 10px;
    }

    textarea {
        width: 100%;
        height: 120px;
        border-radius: 8px;
        border: 1px solid $light-light_grey;
        padding: 10px;
        color: $light-full_Black;
        text-align: left;
        font-family: "Gilroy-Medium", sans-serif;
        font-size: 12px;

        @media (max-width:1600px) {
            height: 89px;
        }

        &:focus {
            outline: none;
        }
    }
}

.addmoreitems-list {
    .cus-row {
        margin: 0 -8px;
        gap: 16px 0;

        @media (max-width:767px) {
            gap: 10px 0;
        }

        .col-4 {
            padding: 0 8px;

            @media (max-width:1366px) {
                width: calc(100% / 2);
            }

            @media (max-width:767px) {
                width: calc(100% / 1);
            }
        }
    }
}

.payment-wrap-modal {
    .chicking-modal-wraper {
        gap: 15px;
    }

    .payment-wrap {
        ul {
            @include flex;
            @include flex-direction(column);
            gap: 15px;

            li {
                @include flex;
                @include flex-align;
                @include flex-justify(space-between);

                &:hover {
                    .checkbox {
                        opacity: 1;
                    }
                }

                .checkbox {
                    transition: all 0.3s ease-in-out;
                    padding: 0 0.5rem 0 0;
                }
            }
        }

        p {
            font-family: "Gilroy-Medium", serif;
            font-size: 12px;
            color: $light-chilly_red;
            margin-top: 10px;
        }

        .payment-list {
            @include flex;
            @include flex-align;
            border-radius: 8px;
            gap: 8px;

            .icon-block {
                @include flex;
                @include flex-align;
                @include flex-justify;
                width: 50px;
                height: 50px;
                padding: 12px 11px;
                background: #F5F5F5;
                border-radius: 8px;
            }

            span {
                color: $light-full_Black;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 12px;

                text-transform: capitalize;

                small {
                    display: block;
                    font-size: 10px;

                }
            }
        }
    }

    .chicking-modal-footer {
        button {
            display: block;
            width: 100% !important;
        }
    }
}


.category-detail-modal {
    padding: 20px 0;

    .chicking-modal-wraper {
        max-width: 800px;
        padding: 0;
        gap: 0;
        margin: 0;
        overflow: hidden;
        border-radius: 15px;

        @media (max-width: 1440px) {
            max-width: 1100px;
        }

        @media (max-width: 1366px) {
            max-width: 720px;
            border-radius: 10px;
        }

        @media (max-width:1200px) {
            border-radius: 6px;
        }

        .add-btn {
            color: #ffffff;
            text-align: center;
            font-family: "Gilroy-Bold", sans-serif;
            font-size: 16px;
            text-transform: uppercase;
            padding: 6px 9px;
            border-radius: 12px;
            background: #be1622;
            transition: all 0.3s ease-in-out;
            gap: 17px;
            display: flex;
            align-items: center;

            @media (max-width:1600px) {
                font-size: 14px;
                border-radius: 8px;
            }

            @media (max-width:1366px) {
                font-size: 12px;
                border-radius: 4px;
            }

            svg {
                path {
                    stroke: $light-negative;
                }
            }
        }

        .chicking-modal-header {
            position: relative;

            @media (max-width:767px) {
                padding: 10px 10px 5px 10px;
            }

            button {
                padding: 0;
                position: absolute;
                top: 30px;
                right: 30px;
                margin: auto;
                margin-right: 0;

                @media (max-width:1600px) {
                    top: 20px;
                    right: 20px;
                }

                @media (max-width:767px) {
                    position: relative;
                    inset: 0;
                }
            }
        }

        .chicking-modal-body {
            background: #EEF0F0;
            padding-right: 0;
            overflow: hidden !important;
            max-height: calc(100vh - 110px) !important;

            @media (max-width:1199px) {
                overflow: auto !important;
            }

            .slick-track {
                margin: 0 !important;
                display: flex !important;

            }

            .slick-slide {
                height: inherit !important;

                &:first-of-type {
                    margin-left: 0 !important;
                }
            }

            .slick-slide>div {
                height: 100%;
            }


        }

        .detail-modal-wrap {
            display: flex;
            flex-direction: column;
            height: 100%;

            &.small-content {
                height: auto !important;
            }

            .slick-slide {
                margin-right: 3px;
                margin-left: 3px;
                border-radius: 6px;
                border: 1px solid $light-amber_card_background;
                background: $light-negative;
                width: 200px !important;
            }

            .category-modal-carousel-item {
                border-radius: 6px;
                height: 100%;
                overflow: hidden;


                .btn {
                    width: 100%;
                    padding: 13px 15px;
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    min-height: 56px;
                    height: 100%;
                    transition: all 0.3s ease-in-out;

                    @media (max-width:1440px) {
                        padding: 10px 12px;
                        min-height: 48px;
                    }

                    h6 {
                        color: $light-full_Black;
                        font-family: "Gilroy-Bold", sans-serif;
                        font-size: 14px;
                        display: flex;
                        flex-direction: column;
                        gap: 3px;

                        p {
                            color: $light-dark_grey;
                            font-family: "Gilroy-Regular", sans-serif;
                            font-size: 10px;
                            display: flex;
                            gap: 5px;

                            span {
                                color: $light-dark_grey;
                            }
                        }
                    }
                }

                .checkbox span {
                    &:before {
                        background: #FBF0F0;
                        border: none;
                        border-radius: 2px;
                    }

                }

                .checkbox input:checked+span:after {
                    background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/category-modal-check.svg) center no-repeat;
                    left: 0px;
                }

                &.isActive {
                    background: $dark-chilly_red;

                    .btn {

                        h6 {
                            color: $light-negative;

                            p,
                            span {
                                color: $light-negative;
                            }
                        }
                    }

                    .checkbox span {
                        &:before {
                            background: #E54450;
                        }
                    }

                    .checkbox input:checked+span:after {
                        background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/category-modal-check.svg) center no-repeat;
                        left: 0px;
                    }
                }

                &.is-success {
                    border: 1px solid #C4D9AF;
                    background: #FBFDF9;

                    .btn {
                        p {
                            color: #30952E;
                        }
                    }

                    .checkbox {
                        span:before {
                            content: "";
                            border-radius: 6px;
                            background: #E4F0E6;
                            display: inline-block;
                            position: relative;
                            vertical-align: middle;
                            cursor: pointer;
                            width: 18px;
                            height: 18px;
                            transition: all 0.5s ease-in-out;
                        }
                    }

                    .checkbox input:checked+span:after {
                        background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/category-modal-success-check.svg) center no-repeat;
                    }
                }

                &.is-error {
                    border: 1px solid $dark-chilly_red;
                    background: #FFFDFD;

                    .btn {
                        p {
                            color: #CD4B4B;
                        }
                    }

                    .checkbox {
                        span:before {
                            content: "";
                            border-radius: 6px;
                            background: #E4F0E6;
                            display: inline-block;
                            position: relative;
                            vertical-align: middle;
                            cursor: pointer;
                            width: 18px;
                            height: 18px;
                            transition: all 0.5s ease-in-out;
                        }
                    }

                    .checkbox input:checked+span:after {
                        background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/category-modal-success-check.svg) center no-repeat;
                    }
                }
            }
        }

        .left-block {
            @include flex;
            gap: 20px;
            background: $light-negative;
            padding: 40px;
            height: auto;
            transition: all 0.8s ease-in-out;

            @media (max-width: 1600px) {
                padding: 20px;
                gap: 15px;
            }

            @media (max-width: 1366px) {
                gap: 10px;
                padding: 10px;
            }

            @media (max-width:1199px) {
                padding: 10px;
            }

            @media (max-width:767px) {
                flex-direction: column;
                padding-top: 0;
            }

            &.scoll-active {

                .left-block .description-block p {
                    height: 39px !important;
                }

                .description-block {
                    gap: 5px;
                }

                @media (max-width:767px) {
                    height: auto;
                }

                h3 {
                    font-size: 18px;
                    line-height: 24px;
                }

                .txt-block {
                    gap: 10px;
                    width: calc(100% - 150px);

                    @media (max-width:767px) {
                        width: 100%;
                    }
                }

                .img-block {
                    min-width: 150px;
                    width: 150px;
                    height: 150px;

                    @media (max-width:1366px) {
                        min-width: 90px;
                        width: 90px;
                        height: 90px;
                    }

                    img {
                        border-radius: 10px;
                    }
                }

                .share-block {
                    button {
                        padding: 5px !important;
                        border-radius: 5px !important;
                    }
                }
            }

            .img-block {
                position: relative;
                display: flex;
                min-width: 300px;
                width: 300px;
                height: 300px;
                transition: all 0.8s ease-in-out;
                background: $light-prime_background;
                border-radius: 5px;
                overflow: hidden;

                @media (max-width: 1600px) {
                    height: 220px;
                    width: 220px;
                    min-width: 220px;
                }

                @media (max-width: 1366px) {
                    height: 140px;
                    width: 140px;
                    min-width: 140px;
                }

                @media (max-width: 767px) {
                    height: auto;
                    min-width: 100%;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                .offer-block {
                    position: absolute;
                    top: 20px;
                    @include flex;
                    @include flex-justify(flex-end);
                    width: 100%;
                    margin: auto;
                    right: 20px;
                    z-index: 99;

                    @media (max-width:1366px) {
                        top: 10px;
                        right: 10px;
                    }

                    .share-block {
                        @include flex;
                        gap: 5px;

                        button {
                            border-radius: 12px;
                            background: #f3e6d3;
                            @include flex;
                            @include flex-justify;
                            @include flex-align;
                            padding: 14px;
                            gap: 10px;
                            transition: all 0.5s ease-in-out;
                            border: 1px solid transparent;

                            @media (max-width:1366px) {
                                padding: 8px;
                                border-radius: 4px
                            }

                            svg {
                                transition: all 0.3s ease-in-out;
                                stroke: $light-chilly_red;
                                stroke-width: 1px;

                                path {
                                    fill: transparent;
                                    transition: all 0.3s ease-in-out;
                                }
                            }

                            &.like-icon {
                                svg {
                                    path {
                                        stroke: $light-chilly_red;
                                    }
                                }

                                &.isActive {
                                    svg {
                                        path {
                                            fill: $light-chilly_red;
                                        }
                                    }
                                }
                            }

                            &.isActive {
                                svg {
                                    path {
                                        fill: $light-negative;
                                    }
                                }
                            }

                            &:hover {
                                &.like-icon {
                                    svg {
                                        path {
                                            fill: $light-chilly_red;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .txt-block {
                @include flex;
                @include flex-direction(column);
                gap: 20px;
                width: calc(100% - 300px);

                @media (max-width:1600px) {
                    width: calc(100% - 270px);
                }

                @media (max-width:1440px) {
                    width: calc(100% - 260px);
                }

                @media (max-width:1366px) {
                    width: calc(100% - 170px);
                }

                @media (max-width:1280px) {
                    width: calc(100% - 180px);
                }

                .category-quantity-wrap {
                    display: flex;
                    justify-content: space-between;

                    @media (max-width:767px) {
                        flex-direction: column;
                        gap: 8px;
                    }
                }

                @media (max-width:1600px) {
                    gap: 15px;
                }

                @media (max-width: 1366px) {
                    gap: 10px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                }

                h3 {
                    color: $light-full_Black;
                    font-family: 'Gilroy-Bold', sans-serif;
                    font-size: 28px;
                    line-height: 28px;
                    letter-spacing: -0.28px;
                    padding-right: 30px;

                    @media (max-width: 1600px) {
                        font-size: 20px;
                        line-height: 24px;
                    }

                    @media (max-width: 1280px) {
                        font-size: 18px;
                        line-height: 24px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 24px;
                        padding-right: 0;
                    }
                }

                .graphic-block-wrap {
                    @include flex-justify(flex-start);

                    span {
                        color: $light-dark_grey;
                        font-family: 'Gilroy-Medium', sans-serif;
                        font-size: 16px;
                        line-height: 26px;
                        letter-spacing: -0.16px;

                        @media (max-width: 1366px) {
                            font-size: 14px;
                        }
                    }
                }
            }

            .category-quantity {
                @include flex;
                align-items: center;
                gap: 10px;
                flex-direction: row-reverse;

                @media (max-width: 767px) {
                    justify-content: space-between;
                    margin-top: 0;
                }

                .quantitywrap {
                    width: 80px;
                    padding: 6px 8px;
                    justify-content: space-between;

                    .btn {
                        width: 16px;
                    }

                    .formGroup {
                        width: 19px !important;
                    }

                    .formControl {
                        width: 19px !important;
                        padding: 0 0px !important;
                        color: #000 !important;
                    }
                }

                span {
                    color: $light-full_Black;
                    font-family: 'Gilroy-Bold', sans-serif;
                    font-size: 19px;

                    line-height: 20px;
                    letter-spacing: -0.19px;

                    del {
                        color: $light-amber;
                        font-family: "Gilroy-Regular", sans-serif;
                        font-size: 15px;
                        font-weight: 400;
                        letter-spacing: -0.15px;
                        text-decoration-line: strikethrough;
                        margin-right: 10px;
                    }

                    @media (max-width: 1440px) {
                        font-size: 17px;
                    }

                    @media (max-width: 1366px) {
                        font-size: 14px;
                    }
                }
            }

            .description-block {
                @include flex;
                @include flex-direction(column);
                gap: 20px;

                @media (max-width: 1600px) {
                    gap: 15px;
                }

                @media (max-width: 1366px) {
                    gap: 10px;
                }

                h4 {
                    color: $light-full_Black;
                    font-family: 'Gilroy-Bold', sans-serif;
                    font-size: 19px;
                    letter-spacing: -0.19px;

                    @media (max-width: 1600px) {
                        font-size: 17px;
                    }

                    @media (max-width: 1366px) {
                        font-size: 14px;
                    }
                }

                p {
                    color: $light-full_Black;
                    font-family: 'Gilroy-Regular', sans-serif;
                    font-size: 12px;
                    line-height: 18px;

                    button {
                        color: $light-chilly_red;
                        text-decoration-line: underline;
                        padding: 0;
                        margin-left: 5px;
                        font-family: "Gilroy-Medium", sans-serif;
                        font-size: 12px;
                        text-transform: unset;
                    }
                }
            }
        }

        .right-block {
            padding: 40px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-right: 0px;
            flex-grow: 1;
            overflow: auto;

            @media (max-width: 1600px) {
                padding: 20px;
                gap: 15px;
                padding-right: 0px;
            }

            @media (max-width: 1440px) {
                gap: 10px;
            }

            @media (max-width:1366px) {
                padding: 10px;
            }

            @media (max-width:1199px) {
                padding: 15px;
                padding-right: 0px;
            }

            @media (max-width:767px) {
                overflow: unset;
                padding: 10px;
            }

            .choice-list-wrap {
                display: flex;
                flex-direction: column;
                gap: 20px;
                flex-grow: 1;
                overflow: auto;
                padding-right: 20px;

                @media (max-width:1440px) {
                    gap: 10px;
                }

                @media (max-width:1366px) {
                    padding-right: 10px;
                }

                @media (max-width:767px) {
                    overflow: unset;
                    padding-right: 0;
                }

                .cartitems-wrap {
                    border: none;

                    @media (max-width:1366px) {
                        padding: 10px;
                    }
                }
            }

            .choice-list {
                border-radius: 12px;
                background: #FFF;
                padding: 20px;

                @media (max-width:1440px) {
                    padding: 15px;
                    border-radius: 8px;
                }

                @media (max-width:1366px) {
                    padding: 10px;
                    border-radius: 4px;
                }

                &.is-error {
                    border: 1px solid #D36666;
                }
            }

            .choice-title {
                @include flex;
                @include flex-align;
                @include flex-justify(space-between);
                margin-bottom: 8px;

                h4 {
                    color: $light-full_Black;
                    text-align: center;
                    font-family: 'Gilroy-Bold', sans-serif;
                    font-size: 19px;

                    @media (max-width: 1600px) {
                        font-size: 17px;
                    }

                    @media (max-width: 1366px) {
                        font-size: 14px;
                    }

                    @media (max-width: 767px) {
                        font-size: 12px;
                    }


                }

                .description-error-msg {
                    color: $light-chilly_red;
                    font-family: "Gilroy-Medium", sans-serif;
                    font-size: 12px;
                }

                .text-want {
                    color: $light-full_Black;
                    text-align: left;
                    font-family: "Gilroy-Regular", sans-serif;
                    font-size: 12px;
                    display: block;
                }

                .selected-block {
                    @include flex;
                    padding: 9px 11px;
                    @include flex-align(flex-start);
                    @include flex-direction(column);
                    flex-wrap: wrap;
                    border-radius: 6px;
                    border: 1px solid $light-amber_card_background;
                    background: $light-negative;

                    @media (max-width: 767px) {
                        padding: 5px;
                    }

                    .icon-block {
                        @include flex;
                        @include flex-justify(space-between);
                        @include flex-align;
                        width: 100%;

                        li {
                            &.icon-bg {
                                border-radius: 2px;
                                border: 1px solid $light-shade_prime;
                                background: $light-green;
                                width: 15px;
                                height: 15px;
                                @include flex;
                                @include flex-align;
                                @include flex-justify;
                            }

                            span {
                                color: $light-amber;
                                text-align: center;
                                font-family: 'Gilroy-Bold', sans-serif;
                                font-size: 12px;
                            }

                            &:last-child {
                                margin: auto;
                                margin-right: 0;
                            }
                        }
                    }

                    h6 {
                        padding: 4px 7px;
                        color: $light-full_Black;
                        text-align: center;
                        font-family: 'Gilroy-Medium', sans-serif;
                        font-size: 12px;

                        @media (max-width: 767px) {
                            padding: 3px;
                        }
                    }
                }
            }

            .cartitems-list {
                @include flex;
                @include flex-direction(column);
                gap: 15px;

                @media (max-width:1366px) {
                    gap: 10px;
                }

                .cartitem {
                    @include flex;
                    gap: 15px;

                    @media (max-width: 1440px) {
                        gap: 10px;
                    }

                    .img-block {
                        width: 90px;
                        height: 90px;
                        flex: none;

                        @media (max-width: 1440px) {
                            width: 60px;
                            height: 60px;
                        }

                        @media (max-width: 1280px) {
                            width: 40px;
                            height: 40px;
                        }

                        @media (max-width: 767px) {
                            width: 80px;
                            height: auto;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 8px;
                        }
                    }

                    .cart-details {
                        width: calc(100% - 60px);
                        @include flex;
                        flex-direction: row;
                        @include flex-justify(space-between);
                        gap: 10px 0;
                        align-items: center;

                        .title-box {
                            flex-direction: column;
                            align-items: flex-start;

                            h4 {
                                color: $light-full_Black;
                                font-size: 18px;
                                text-transform: capitalize;
                                word-break: break-all;
                                padding-right: 10px;

                                @media (max-width: 1600px) {
                                    font-size: 16px;
                                }

                                @media (max-width: 1366px) {
                                    font-size: 14px;
                                }
                            }

                            span {
                                color: $light-dark_grey;
                                text-align: left;
                                font-family: 'Gilroy-Regular', sans-serif;
                                font-size: 16px;
                                letter-spacing: -0.333px;
                                text-transform: capitalize;
                                margin-top: 6px;
                                display: block;

                                @media (max-width: 1440px) {
                                    font-size: 16px;
                                }

                                @media (max-width: 1366px) {
                                    font-size: 14px;
                                }
                            }
                        }

                        .quantity-details {
                            .quantitywrap {
                                border-radius: 8px;
                                border: 1px solid $light-light_grey !important;

                                input {
                                    background: $light-light_grey !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .toggle-button-cover {
        .slider {
            border: 1px solid $light-board_grey !important;

            &::before {
                background: $light-dark_grey !important;
            }

            &::after {
                right: 27px;
                top: 10px;
            }
        }

        input:checked+.slider:before {
            background: $dark-chilly_red !important;
        }
    }

    .chicking-modal-footer {
        @include flex;
        @include flex-align;
        @include flex-justify(space-between);
        border-top: 1px solid $light-light_grey;
        background: $light-negative;
        padding: 20px 40px;
        padding-right: 40px !important;
        gap: 30px;

        @media (max-width: 1600px) {
            padding: 10px 20px !important;
            padding-right: 20px !important;
        }

        @media (max-width: 1366px) {
            padding: 10px !important;
            gap: 20px;
        }

        @media (max-width: 991px) {
            @include flex-align(flex-start);
            gap: 15px;
            padding: 15px !important;
        }

        @media (max-width:767px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .checkbox {
            @include flex;
            @include flex-align(flex-start);
            gap: 10px;

            span {
                width: 26px;
                height: 26px;

                @media (max-width:1600px) {
                    width: 20px;
                    height: 20px;
                }

                @media (max-width:991px) {
                    width: 15px;
                    height: 15px;
                }

                &:before {
                    width: 26px;
                    height: 26px;
                    border-radius: 7px;
                    border: 1px solid $light-board_grey;

                    @media (max-width:1600px) {
                        width: 20px;
                        height: 20px;
                        border-radius: 4px;
                    }

                    @media (max-width:991px) {
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            input:checked+span:after {
                inset: 0;
                margin: auto;
                top: 3px;
                left: 1px;
                background-size: 14px;

                @media (max-width:991px) {
                    top: 8px;
                    left: 0px;
                    background-size: 10px;
                }
            }

            p {
                color: $light-full_Black;
                font-family: "Gilroy-regular", sans-serif;
                font-size: 12px;
                line-height: normal;

                b {
                    color: $light-chilly_red;
                    font-family: 'Gilroy-Bold', sans-serif;
                }
            }
        }

        .btn-group {
            @media (max-width: 767px) {
                width: 100% !important;
            }
        }

        .primary-btn {
            padding: 16px 21px !important;
            gap: 35px !important;
            transition: all 0.5s ease-in-out;
            border-radius: 12px !important;
            width: 287px !important;


            &:hover {
                background: $light-chilly_red_dark;
            }

            @media (max-width: 1600px) {
                gap: 30px !important;
            }

            @media (max-width:1440px) {
                padding: 10px 15px !important;
                width: 260px !important;
                gap: 20px !important;
            }

            @media (max-width: 1280px) {
                gap: 20px !important;
            }

            @media (max-width: 1199px) {
                padding: 12px 16px !important;
            }

            @media (max-width: 991px) {
                width: 240px !important;
            }

            @media (max-width: 767px) {
                width: 100% !important;
                justify-content: space-between !important;
            }

            .add_to_cart-btn {
                display: flex;
                gap: 11px;
                flex: none;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 16px;
                text-transform: uppercase;

                @media (max-width:1600px) {
                    font-size: 14px;
                }

                @media (max-width:1366px) {
                    font-size: 12px;
                }

                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            h6 {
                color: $light-negative;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 12px;
                text-transform: uppercase;
                text-align: left;
                display: block;
                margin-bottom: 3px;
                flex: none;

                p {
                    color: $light-negative;
                    font-family: "Gilroy-Medium", sans-serif;
                    font-size: 10px;
                    text-transform: capitalize;

                    span {
                        color: $light-negative;
                        font-size: 10px;
                    }
                }
            }



            &.isdisabled {
                background: $light-light_grey !important;
                cursor: not-allowed;
                border: 1px solid $light-light_grey !important;
            }
        }
    }

    .cartitems-wrap {
        padding: 20px;
    }
}

.toggle-button-cover {
    @include flex;
    @include flex-align;
    @include flex-justify(space-between);

    @media (max-width:767px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 44px;
        height: 26px;
        padding: 4px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 10px;
        border: 1px solid $light-light_grey;

        &:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: 2px;
            bottom: 2px;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 6px;
            border: 1px solid $light-shade_prime;
            background: $light-light_grey;
        }

        &::after {
            content: '';
            background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/closeIcon.svg);
            position: absolute;
            right: 26px;
            top: 9px;
            z-index: 99;
            height: 6px;
            width: 7px;
            transition: all 0.3s ease-in-out;
        }
    }

    input:checked+.slider:before {
        transform: translateX(18px);
        border: 1px solid $light-shade_prime;
        background: $light-light_grey;
        background: $light-chilly_red;
    }

    input:checked+.slider::after {
        content: '';
        transform: translateX(17px);
        background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/TickIcon.svg);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;

        &:before {
            border-radius: 50%;
        }
    }
}

.outlets {
    display: flex;
    justify-content: center;

    .slick-list {
        width: calc(100% + 20px);
        margin-left: -10px;
    }

    .slick-prev {
        left: -70px;
        z-index: 999;

        @media (max-width: 800px) {
            left: 10px;
        }
    }

    .slick-next {
        right: -70px;
        z-index: 9;
        left: unset;

        @media (max-width: 800px) {
            right: 10px;
        }
    }

    h4 {
        color: $light-full_Black;
        font-family: 'Gilroy-ExtraBold', sans-serif;
        font-size: 24px;
        text-transform: uppercase;
        margin-bottom: 10px;

        @media (max-width:1440px) {
            font-size: 20px;
        }

        @media (max-width:1199px) {
            font-size: 18px;
        }

        small {
            color: $light-chilly_red;
            font-family: 'Gilroy-Medium', sans-serif;
            font-size: 24px;

            @media (max-width:1440px) {
                font-size: 20px;
            }

            @media (max-width:1199px) {
                font-size: 18px;
            }
        }
    }

    p {
        color: $light-negative;
        color: #111;
        font-family: "Gilroy-Light", sans-serif;
        font-size: 18px;
        line-height: 26px;

        @media (max-width:1600px) {
            font-size: 16px;
        }

        @media (max-width:1366px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.outlets-items {
    figure {
        position: relative;
        border-radius: 20px;
        height: 275px;
        padding: 0 10px;
        width: 100%;
        overflow: hidden;

        @media(max-width:1366px) {
            height: 200px;
        }

        @media (max-width:767px) {
            border-radius: 10px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 116px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
            left: 10px;
            right: 10px;
            border-radius: 20px;
            z-index: 1;

            @media (max-width:767px) {
                border-radius: 10px;
            }
        }

        img {
            border-radius: 20px;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media (max-width:767px) {
                border-radius: 10px;
            }
        }

        figcaption {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            padding: 15px 25px;
            transform: translate(0px, 70px);
            transition: all 0.5s ease-in-out;
            z-index: 3;

            @media (max-width:991px) {
                bottom: 10px;
            }

            h6 {
                padding: 10px;
                color: $light-negative;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 18px;
                letter-spacing: -0.18px;

                @media (max-width:1366px) {
                    font-size: 16px;
                }

                @media (max-width:991px) {
                    padding: 0;
                    font-size: 14px;
                }

                span {
                    color: $light-negative;
                    font-family: 'Gilroy-Regular', sans-serif;
                    font-size: 14px;

                    line-height: 20px;
                    letter-spacing: -0.14px;
                    display: block;
                }
            }

            .btn-group {
                @include flex;
                @include flex-align;
                gap: 14px;
                padding: 10px 0;
                justify-content: space-between;

                .btn {
                    display: flex;
                    width: 100%;
                    padding: 14px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    align-self: stretch;
                    border-radius: 12px;
                    background: $light-chilly_red;
                    color: $light-negative;
                    text-align: center;
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 12px;
                    text-transform: uppercase;

                    @media (max-width:1440px) {
                        padding: 10px;
                    }

                    @media (max-width:1366px) {
                        border-radius: 8px;
                    }

                    @media (max-width:767px) {
                        border-radius: 4px;
                    }
                }

                .btn-icon {
                    border-radius: 12px;
                    background: rgba(255, 255, 255, 0.30);
                    height: 45px;
                    width: 45px;
                    @include flex;
                    @include flex-align;
                    @include flex-justify;

                    @media (max-width:1440px) {
                        height: 38px;
                        width: 38px;
                        border-radius: 8px;
                    }

                    @media (max-width:1199px) {
                        height: 32px;
                        width: 32px;
                        border-radius: 6px;
                    }

                    svg {
                        width: 14px;
                        height: 16px;

                        path {
                            stroke-width: 1px;
                            stroke: $light-negative;
                            fill: transparent !important;
                        }
                    }
                }
            }
        }

        &:hover {
            figcaption {
                transform: translate(0px, 10px);
            }
        }
    }
}

.slick-arrow {
    display: flex !important;
    width: 46px !important;
    height: 45px !important;
    padding: 14px !important;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid $light-light_grey !important;
    background: $light-shaded_white url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-left-black.svg) no-repeat !important;
    background-position: center !important;
    z-index: 9;

    @media(max-width:1600px) {
        width: 40px !important;
        height: 40px !important;
        border-radius: 8px !important;
    }

    @media(max-width:1366px) {
        width: 30px !important;
        height: 30px !important;
        border-radius: 6px !important;
    }

    @media(max-width:767px) {
        display: none !important;
    }

    &:hover {
        background-color: $light-chilly_red !important;
        background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-left-white.svg) !important;
    }

    &::before {
        display: none !important;
    }

    &.isActive {
        background: $light-chilly_red url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-white.svg) !important;
    }

    &.slick-next {
        background: $light-shaded_white url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-black.svg) no-repeat !important;
        background-position: center !important;

        &.isActive {
            background-image: $light-chilly_red url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-black.svg) !important;
        }

        &:hover {
            background-color: $light-chilly_red !important;
            background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-white.svg) !important;
        }
    }
}

.btn-group {
    @include flex;
    @include flex-align;
    justify-content: flex-end;
    gap: 15px;

    &.btn-left {
        justify-content: flex-start;
    }

    button {
        color: $light-full_Black;
        text-align: center;
        font-family: 'Gilroy-Bold', sans-serif;
        font-size: 12px;
        display: flex;
        width: 117px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        text-transform: capitalize;

        @media (max-width: 991px) {
            font-size: 14px;
        }

        @media (max-width: 991px) {
            font-size: 12px;
        }

        &.close-btn {
            border: 1px solid $light-light_grey;
            background: $light-negative;

        }

        &.primary-btn {
            background: $light-chilly_red;
            color: $light-negative;
            border: 1px solid $light-chilly_red;

        }
    }
}

.fav-food {
    .cart-details {
        .title-box {
            h4 {
                max-width: 320px;
            }

            button {
                border: 1px solid #e6e6e6;
                border-radius: 8px;
            }

            .like-btn.isActive {
                svg {
                    path {
                        fill: $light-chilly_red;
                    }
                }
            }
        }

        .items {
            .quantity-details {
                margin-top: 11px;
            }
        }

        .graphic-block-wrap {
            gap: 10px;
        }
    }
}

.address-book {
    .saved-locations {
        gap: 20px;
        height: auto;

        @media (max-width:1440px) {
            gap: 10px;
        }
    }

    .dropdown-btn {
        position: relative;
        margin: auto;
        margin-right: 0;

        .menu-dot-icon {
            background: transparent !important;
        }

        .dropdown {
            position: absolute;
            top: 0;
            right: 0;

            ul {
                border: 0.5px solid $light-light_grey;
                background: $light-negative;
                border-radius: 5px;
                position: relative;
                top: 30px;
                width: 100px;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -8px;
                    right: 5px;
                    width: 0;
                    height: 0;
                    border-radius: 3px;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 10px solid $light-light_grey;
                    z-index: -1;
                }

                li {
                    border-top: 1px solid $light-light_grey;

                    &:first-child {
                        border: none;
                    }

                    &.delete-btn {
                        button {
                            color: $dark-chilly_red !important;

                        }
                    }


                    button {
                        color: $light-full_Black !important;
                        letter-spacing: -0.12px;
                        padding: 10px !important;
                        display: block;
                        background: transparent !important;
                        text-transform: unset;
                        font-family: "Gilroy-Medium", sans-serif;
                        font-size: 16px;

                        @media (max-width:1440px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .item-locations {
        .item-header {
            align-items: center !important;

            @media (max-width:767px) {
                flex-direction: column-reverse;
                align-items: flex-start !important;
            }

            .dropdown-block {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

            }
        }

        span {
            display: flex;
            padding: 7px 12px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: $light-amber_card_background;
            color: var(--Black, #000);
            font-family: "Gilroy-Medium", sans-serif;
            font-size: 12px;
            white-space: nowrap;
        }

        p {
            color: $light-full_Black;
        }
    }
}

.not-delivery-wrap {
    margin-bottom: 5vh;

    .container {
        margin: auto;

        .not-delivery {
            border-radius: 22px;
            border: 1px dashed $dark-chilly_red;
            background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/not-delivery-bg.png);
            padding: 31px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
            background-color: #FFF2F2;

            @media (max-width:1600px) {
                border-radius: 18px;
            }

            @media (max-width:1366px) {
                border-radius: 14px;
            }

            @media (max-width:767px) {
                flex-direction: column;
                padding: 15px;
                gap: 15px;
                border-radius: 8px;
            }

            img {
                @media (max-width:767px) {
                    width: 50px;
                    height: 55px;
                }
            }

            h4 {
                color: $light-full_Black;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 16px;

                @media (max-width:767px) {
                    text-align: center;
                    margin-bottom: 5px;
                }

                span {
                    color: $light-dark_grey;
                    font-family: "Gilroy-Regular", sans-serif;
                    font-size: 14px;
                    display: block;
                    font-weight: 400;

                    @media (max-width:767px) {
                        text-align: center;
                        font-size: 12px;
                        margin-top: 5px;
                    }
                }
            }

            button {
                border-radius: 5px;
                border: 2px solid $light-amber;
                padding: 12px;
                color: $light-full_Black;
                text-align: center;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 16px;
                background: unset;
                margin-left: 8%;

                &:hover {
                    background: $light-chilly_red;
                    color: $light-full_White;
                    border-color: $light-chilly_red;
                }

                @media (max-width:1199px) {
                    font-size: 14px;
                    margin-left: 0%;
                }

                @media (max-width:767px) {
                    padding: 8px;
                    font-size: 12px;
                }
            }
        }
    }
}

.category-detail-wrap {
    .slick-slider {
        .slick-prev {
            left: -75px;
        }

        .slick-next {
            right: -75px;
        }
    }
}

.cartitems-address-wrap {
    margin-top: 20px;

    .cartitems-title {
        color: $light-full_Black;
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 19px !important;
        letter-spacing: -0.19px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width:1440px) {
            font-size: 17px !important;
        }

        @media (max-width:991px) {
            font-size: 15px !important;
        }

        button {
            color: $light-chilly_red;
            font-family: "Gilroy-Medium", sans-serif;
            font-size: 12px;
            text-decoration-line: underline;
            text-transform: capitalize;
            padding: 0;
        }
    }

    .cartitems-item {

        .list {
            display: flex;
            align-items: center;
            gap: 20px;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 0.5px solid $light-board_grey;
            flex-wrap: wrap;

            &:last-child {
                padding-bottom: 0px;
                margin-bottom: 0px;
                border: none;
            }

            p {
                color: $light-full_Black;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 12px;
                line-height: 18px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 1;


            }

            .btn {
                border-radius: 6px;
                background: $light-full_Black;
                display: flex;
                padding: 7px 10px;
                align-items: center;
                gap: 11px;
                color: $light-negative;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 12px;
                min-height: auto;
            }
        }

        span {
            border-radius: 5px;
            background: rgba(190, 22, 34, 0.20);
            display: flex;
            padding: 15px;
            color: #D75A63;
            font-family: "Gilroy-Medium", sans-serif;
            font-size: 12px;
        }

        .schedule-time-wrap {
            padding: 0 !important;
            width: 100%;
        }

        .opening-time {
            gap: 10px;
        }

        .time-wrap {
            .time-picker__period-buttons {
                overflow: unset !important;
            }
        }

        .opening-time,
        .time-wrap {
            span {
                background: transparent !important;
                padding: 0;
            }
        }
    }
}

.notifications-modal {
    .chicking-modal-title {
        display: flex;
        align-items: flex-start !important;
    }

    .chicking-modal-footer {
        padding: 0 !important;
        border: none !important;

        .btn-group {
            button {
                width: auto !important;
                padding: 12px !important;
            }
        }
    }
}

.terms-conditions {
    direction: ltr; //for footer items arabic content not available that why we need to set direction to ltr

    .container {
        margin: auto;

        @media (max-width:767px) {
            width: 90%;
        }
    }

    .terms-conditions-wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px 0;

        @media (max-width:1600px) {
            gap: 25px 0;
        }

        @media (max-width:1199px) {
            gap: 20px 0;
        }

        .terms-conditions-txt {
            @media (max-width:767px) {
                padding: 0 15px;
            }

            h3 {
                color: $light-full_Black;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 26px;
                line-height: 25px;

                @media (max-width:991px) {
                    font-size: 18px;
                }
            }

            h4 {
                color: $light-full_Black;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 18px;
                line-height: 25px;
                margin-top: 15px;

                @media (max-width:991px) {
                    font-size: 18px;
                }
            }

            p,
            a,
            li {
                color: $light-full_Black;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 16px;
                line-height: 26px;
                margin-top: 20px;


                @media (max-width:1600px) {
                    margin-top: 10px;
                }

                @media (max-width:1366px) {
                    font-size: 14px;
                }

                @media (max-width:767px) {
                    font-size: 12px;
                    line-height: 20px;
                }
            }

            a {
                text-decoration: underline;
                color: $light-chilly_red;
            }

            ul {
                list-style-position: outside;
                padding-left: 45px;

                li {

                    list-style: disc;
                    margin-top: 5px;
                    color: $light-dark_grey;

                }
            }
        }
    }
}

.min-height-100vh {
    .chicking-modal-body {
        min-height: 240px;
    }
}

.empty-cart-wrap {
    .empty-cart-bg {
        background: #FAF9F7;
        border-radius: 5px;
        border: 1px solid #E3E3E3;
        height: 399px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .empty-cart {
            max-width: 336px;

        }
    }
}

.error-page,
.empty-cart-wrap {
    .container {
        margin: auto;
    }

    svg {
        margin: auto;
        display: block;

        @media (max-width:767px) {
            width: 280px;
        }
    }

    .txt-block {
        position: relative;
        z-index: 11;

        h3 {
            color: #000;
            text-align: center;
            font-family: "Gilroy-ExtraBold", sans-serif;
            font-size: 20px;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        p {
            color: $light-full_Black;
            text-align: center;
            font-family: "Gilroy-Regular", sans-serif;
            font-size: 14px;
        }

        button {
            border-radius: 9px;
            background: $light-chilly_red;
            width: 252px;
            height: 49px;
            text-align: center;
            color: $light-negative;
            font-family: "Gilroy-Bold", sans-serif;
            font-size: 12px;
            text-transform: uppercase;
            margin: auto;
            margin-top: 20px;
            display: block;

            @media (max-width:1600px) {
                width: 200px;
                height: 42px;
            }

            @media (max-width:1366px) {
                width: 150px;
                height: 38px;
            }
        }
    }
}

.error-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin: auto;
    background: linear-gradient(180deg, rgba(255, 0, 0, 0.06) 0%, rgba(153, 0, 0, 0.06) 100%);

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        bottom: unset;
        background: #fff;
        height: calc(50vh - 6px);
        z-index: 9;
    }
}

.conditions-txt {
    p {
        color: $light-full_Black;
        font-family: "Gilroy-Medium", sans-serif;
        font-size: 12px;
        display: flex;
        align-items: center;

        @media (max-width:480px) {
            font-size: 11px;
        }

    }

    button {
        color: var(--Amber, #F39200) !important;
        font-family: "Gilroy-Medium", sans-serif !important;
        font-size: 12px !important;
        text-decoration-line: underline !important;
        padding: 0 !important;
        height: auto !important;
        width: auto !important;
        background: transparent !important;
        margin-left: 2px !important;
        text-transform: capitalize !important;

        @media (max-width:480px) {
            font-size: 11px !important;
        }
    }
}

.contact-bock {

    h3 {
        color: $light-full_Black;
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 26px;
        margin-bottom: 20px;
    }

    .cus-row {
        @media (max-width:991px) {
            flex-direction: column;
            gap: 20px;

            .col-3 {
                width: 100%;
            }
        }
    }
}

.contact-list {
    height: 100%;

    &.contact-address {
        border-radius: 5px;
        border: 1px solid $light-light_grey;
        background: $light-negative;
        padding: 20px;
    }

    h4 {
        color: $light-full_Black;
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 18px;
        margin-bottom: 10px;
    }

    p {
        color: var(--Dark-Grey, #7F7F7F);
        font-family: "Gilroy-Medium", sans-serif;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
        line-height: 24px;
    }

    a {
        color: $light-chilly_red;
    }
}

.order-details-modal {
    .chicking-modal-body {
        max-height: calc(100vh - 275px) !important;
    }

    .txt-block {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media (max-width:991px) {
            gap: 10px;
        }

        small {
            display: flex;
            padding: 6px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 1px solid $light-light_grey;
            color: $light-full_Black;
            text-align: center;
            font-family: "Gilroy-Bold", sans-serif;
            font-size: 12px;
            font-weight: 400;
            min-width: 48px;
            width: fit-content;
            height: 27px;
        }
    }

    .description-txt {
        margin-top: 15px;

        h4 {
            color: $light-full_Black;
            font-family: "Gilroy-Bold", sans-serif;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 5px;
        }

        p {
            color: $light-full_Black;
            font-family: "Gilroy-Medium", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
        }
    }

    .choice-chicken-wrap {
        .choice-list-wrap {
            .list {
                @media (max-width:767px) {
                    flex-direction: row;
                    align-items: center;
                }
            }
        }
    }

    .btn-group {
        .close-btn {
            background-color: #FAF9F7;
            color: #000000;
            border: solid 1px #E6E6E6;
            text-transform: unset;

            &:hover {
                background: #E6E6E6 !important;
            }
        }
    }
}

.footer-item-active {
    font-weight: bold;
}

.personalDetail-form {
    .formField {
        width: 495px !important;

        @media (max-width:1199px) {
            width: 400px !important;
        }

        @media (max-width:767px) {
            width: 100% !important;
        }
    }
}

.coming-soon {
    background: $dark-chilly_red url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/coming-soon-bg.png) no-repeat;
    background-position: right bottom;
    background-size: contain;
    height: 100vh;
    overflow: hidden;

    .container {
        height: 100%;
        margin: auto;
    }

    .coming-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        gap: 97px 0;

        @media (max-width:1600px) {
            gap: 85px 0;
        }

        @media (max-width:1440px) {
            gap: 70px 0;
        }

        @media (max-width:1280px) {
            gap: 40px 0;
        }
    }

    img {
        object-fit: contain;
        max-width: 348px;

        @media (max-width:1024px) {
            max-width: 300px;
        }

        @media (max-width:1024px) {
            max-width: 260px;
        }
    }

    h4 {
        color: #FFF;
        text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.25);
        font-family: "Gilroy-ExtraBold", sans-serif;
        font-size: 112px;
        font-weight: 400;

        @media (max-width:1600px) {
            font-size: 90px;
        }

        @media (max-width:1440px) {
            font-size: 60px;
        }

        @media (max-width:1280px) {
            font-size: 50px;
        }

        @media (max-width:991px) {
            font-size: 40px;
        }

        @media (max-width:767px) {
            font-size: 36px;
        }
    }

    span {
        color: #EDDABF;
        text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.25);
        font-family: "Gilroy-Light", sans-serif;
        font-size: 47px;
        font-style: normal;
        font-weight: 400;
        margin-top: 18px;

        @media (max-width:1600px) {
            font-size: 40px;
        }

        @media (max-width:1440px) {
            font-size: 36px;
        }

        @media (max-width:1280px) {
            font-size: 30px;
        }

        @media (max-width:991px) {
            font-size: 26px;
        }

        @media (max-width:767px) {
            font-size: 22px;
        }
    }
}

.copyRight {
    color: #7D7A7A;
    font-family: "Gilroy-medium", sans-serif;
    font-size: 18px;

    @media (max-width:1440px) {
        font-size: 14px;
    }

    @media (max-width:991px) {
        font-size: 12px;
    }
}

.logoutIcon {
    margin-right: 10px;
}

.deleteAccountRed {
    color: red ! important;
    text-decoration: underline;
}

.take-away-outlet-no-data {
    margin-top: 15px;
}

.payment-loader {
    img {
        display: block;
        margin: auto;
        margin-bottom: 20px;
    }

    h6 {
        color: #2D2D2D;
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 24px;
        margin-bottom: 10px;
        text-align: center;

        @media (max-width:1600px) {
            font-size: 20px;
        }

        @media (max-width:991px) {
            font-size: 18px;
        }
    }

    p {
        color: $light-full_Black;
        text-align: center;
        font-family: "Gilroy-Regular", sans-serif;
        font-size: 19px;
        padding-bottom: 40px;

        @media (max-width:1600px) {
            font-size: 16px;
        }

        @media (max-width:991px) {
            font-size: 14px;
        }
    }
}


.alert-modal {
    .chicking-modal-wraper {
        .chicking-modal-body {
            svg {
                margin: 20px auto auto;
            }

            p {
                text-align: center;
                font-family: "gilroy-bold", sans-serif;
            }
        }

        .chicking-modal-footer {
            margin-top: 20px;

            .primary-btn {
                height: 44px;
                padding: 14px 25px !important;
                width: 100% !important;
            }
        }
    }
}

@media (max-width:480px) {
    .Toastify__toast-container {
        width: 90vw;
    }
}

.auth-modal {
    .chicking-modal-wraper {
        max-width: 381px;
        gap: 0;

        .chicking-modal-header .chicking-modal-title {
            h4 {
                text-align: center;
                display: block;
                margin: auto;
            }

            button {
                display: none;
            }
        }

        .chicking-modal-body {
            p {
                text-align: center;
                margin-top: 5px;
            }
        }

        .chicking-modal-footer {
            margin-top: 20px;

            .btn-group {
                gap: 20px;

                button {
                    width: 100% !important;
                }
            }
        }
    }
}

// home page style
$containerWidth: 1300px;

header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .top_controls_wrapper {
        background: rgba(251, 212, 212, 0.29);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 0;

        .container {
            display: flex;
            justify-content: space-between;
        }

        .mode_switcher {
            display: flex;
            gap: $gap-regular;

            .language_wrap {
                display: flex;

                button {
                    padding: 5px 15px 3px;
                    border-radius: $border-radius-small;
                    font-size: 12px;

                    @media (max-width: 1440px) {
                        padding: 5px 10px 3px;
                    }

                    @media (max-width: 767px) {
                        padding: 5px;
                    }

                    &.active {
                        background-color: $light-chilly_red;
                        color: $light-full_White;
                    }
                }
            }

            .colormode_wrap {
                display: flex;

                .colorMode_icon {
                    padding: 5px;
                }
            }
        }

        .location_update {
            display: flex;
            align-items: center;
            gap: 11px;

            @media(max-width:1440px) {
                gap: 10px;
            }

            @media(max-width:767px) {
                width: 100%;
                justify-content: flex-end;
            }

            .current-location-txt {
                text-transform: capitalize !important;
                font-family: "Gilroy-Medium", sans-serif;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: -0.02em;
                padding: 0;
            }

            svg {
                width: 17px;
            }

            span {
                color: $light-dark_grey;
                font-family: 'Gilroy-Medium', sans-serif;
                font-size: 12px;
                line-height: normal;
                letter-spacing: 0.25px;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                white-space: nowrap;

                @media (max-width: 1440px) {
                    font-size: 10px;
                }

                @media (max-width:400px) {
                    max-width: 80px;
                }
            }

            .change-btn {
                padding: 5px 15px;
                border-radius: 30px;
                background-color: $light-chilly_red;
                color: $light-full_White;
                font-size: 12px;
                margin-left: 4px;

                @media (max-width:767px) {
                    padding: 5px 10px;
                    font-size: 10px;
                }

                &:hover {
                    background: $light-chilly_red_dark;
                }

                a {
                    text-transform: unset;
                }
            }
        }
    }

    .header_wrapper {
        display: flex;
        justify-content: center;
        padding: 30px 0;

        @media (max-width: 1440px) {
            padding: 20px 0;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 767px) {
                flex-direction: column;
                gap: 20px;
            }

            .logo_wrapper {
                @media (max-width: 1440px) {
                    width: 140px;
                }

                @media (max-width: 767px) {
                    width: 100px;
                }

                a {
                    display: block;

                    img {
                        width: 190px;
                        height: auto;
                        object-fit: contain;

                        @media (max-width: 1366px) {
                            width: 140px;
                        }

                        @media (max-width: 767px) {
                            width: 100px;
                        }
                    }
                }
            }

            .authControls {
                display: flex;
                align-items: center;
                gap: 15px;

                @media (max-width: 1440px) {
                    gap: 10px;
                }

                @media (max-width: 767px) {
                    flex-wrap: wrap;
                    gap: 7px;
                }

                .cartIconwrap {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    border-radius: 8px;
                    border: 1px solid $light-light_grey;
                    padding: 0;
                    overflow: hidden;

                    .cartIcon {
                        position: relative;
                        display: flex;
                        width: 40px;
                        height: 40px;
                        padding: 8px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 0px 7px 7px 0px;
                        background: $light-light_grey;

                        @media (max-width: 1440px) {
                            width: 36px;
                            height: 36px;
                        }

                        svg {
                            width: 19px;
                            height: 19px;

                            path {
                                stroke: $light-full_Black;
                            }
                        }

                        span {
                            position: absolute;
                            text-align: center;
                            font-size: 10px;
                            background: #be1622;
                            color: #fff;
                            border-radius: 100px;
                            min-width: 14px;
                            height: 14px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: auto;
                            top: 4px;
                            right: 5px;
                            width: auto;
                            padding: 2px;
                            letter-spacing: 0;
                        }
                    }

                    h4 {
                        color: $light-chilly_red;
                        font-family: 'Gilroy-Bold', sans-serif;
                        font-size: 14px;
                        text-transform: uppercase;
                        display: flex;
                        flex-direction: column;
                        gap: 0px;
                        padding: 0px 15px 0px 0px;

                        @media (max-width:767px) {
                            display: none;
                        }

                        small {
                            font-family: 'Gilroy-Bold', sans-serif;
                            color: $light-full_Black;
                            font-size: 12px;
                            text-transform: capitalize;

                            span {
                                font-family: 'Gilroy-Light', sans-serif;
                            }

                            @media (max-width: 1440px) {
                                font-size: 11px;
                            }
                        }
                    }
                }

                .userDatawrap {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    position: relative;

                    @media (max-width: 767px) {
                        gap: 0;
                    }

                    .icon {
                        @media (max-width: 767px) {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            z-index: 10;
                            left: 0;
                            top: 0;
                        }
                    }

                    .userIcon {
                        .avatar {
                            width: 41px;
                            height: 41px;
                            border-radius: 8px;

                            @media (max-width: 1440px) {
                                width: 36px;
                                height: 36px;
                            }

                            .avatar_icon {
                                border-radius: 6px;
                                padding: 0;
                            }
                        }
                    }

                    h4 {
                        display: flex;
                        flex-direction: column;
                        color: $light-full_Black;
                        font-family: 'Gilroy-Bold', sans-serif;
                        font-size: 16px;
                        padding-top: 5px;

                        @media (max-width: 1440px) {
                            font-size: 14px;
                        }

                        @media (max-width: 767px) {
                            display: none;
                        }

                        small {
                            color: $light-dark_grey;
                            font-family: 'Gilroy-Medium', sans-serif;
                            font-size: 12px;

                            @media (max-width: 1440px) {
                                font-size: 11px;
                            }
                        }
                    }
                }

                .default_btn,
                .primary_btn {
                    border-radius: 8px;
                    padding: 12px 14px 10px;
                    font-size: 16px;

                    @media (max-width: 1440px) {
                        padding: 11px 12px 9px !important;
                    }

                    @media (max-width: 1366px) {
                        font-size: 12px;
                    }

                    @media (max-width:767px) {
                        flex: 1 1 auto;
                        font-size: 11px !important;
                        justify-content: center;
                        text-align: center;
                        padding: 12px 6px !important;
                        align-items: center;
                    }
                }

                .primary_btn {
                    display: flex;
                    background: $light-chilly_red;
                    color: $light-full_White;
                }

                .category-btn {
                    background: $light-amber;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        background: #d48207 !important;
                    }
                }

                .avatar .avatar_icon span {
                    font-size: 14px;
                }
            }

            .avatar {
                .avatar_icon {
                    background: $light-amber;
                }
            }
        }
    }
}

.carousel_wrapper {
    display: flex;
    justify-content: center;
    background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/shapebottom.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    min-height: calc(100vh - 175px);
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;

    @media (max-width:1366px) {
        min-height: calc(100vh - 75px);
    }

    @media (max-width:1199px) {
        min-height: auto;
    }

    .container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;


        @media (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            gap: 30px;
        }

        .hero_title {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 40%;
            padding-top: 100px;

            @media (max-width: 1600px) {
                padding-top: 120px;
            }

            @media (max-width: 1366px) {
                padding-top: 50px;
            }

            @media (max-width: 1024px) {
                padding-top: 30px;
            }

            @media (max-width: 991px) {
                padding-top: 20px;
            }

            @media (max-width: 768px) {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: 20px;
            }

            button {
                display: flex;
                width: 180px;
                height: 50px;
                justify-content: center;
                align-items: center;
                border-radius: $border-radius-small;
                background: $light-chilly_red;
                color: $light-full_White;

                &:hover {
                    background: $light-chilly_red_dark;
                }

                @media (max-width: 1366px) {
                    width: 145px;
                    height: 40px;
                }
            }

            h1 {
                color: $light-full_Black;
                font-family: 'Gilroy-ExtraBold', sans-serif;
                font-size: 62px;
                line-height: 71px;
                text-transform: uppercase;

                @media (max-width: 1600px) {
                    font-size: 60px;
                }

                @media (max-width: 1440px) {
                    font-size: 44px;
                    line-height: 52px;
                }

                @media (max-width: 1366px) {
                    font-size: 40px;
                    line-height: 46px;
                }

                @media (max-width: 1024px) {
                    font-size: 35px;
                    line-height: 40px;
                }

                @media (max-width: 768px) {
                    text-align: center;
                    font-size: 28px;
                }

                small {
                    display: block;
                    color: $light-chilly_red;
                    font-family: 'Gilroy-Bold', sans-serif;
                    font-size: 52px;
                    line-height: 71px;
                    text-transform: capitalize;

                    @media (max-width: 1440px) {
                        font-size: 38px;
                        line-height: 43px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 28px;
                        line-height: 33px;
                    }
                }
            }
        }

        .carouselImage {
            width: 56%;

            @media(max-width:1440px) {
                width: 48%;
            }

            @media (max-width: 991px) {
                align-self: center;
            }

            @media (max-width: 768px) {
                width: 100%;
            }

            img {
                display: block;
                margin: auto;
                width: 720px;
                height: auto;
                object-fit: contain;

                @media (max-width: 1600px) {
                    width: 680px;
                    height: auto;
                }

                @media (max-width: 1366px) {
                    width: 440px;
                    height: auto;
                }

                @media (max-width: 1024px) {
                    max-width: 330px;
                    height: auto;
                }

                @media (max-width: 520px) {
                    max-width: 100%;
                }
            }
        }

        span {
            color: #111;
            font-family: "Gilroy-Light", sans-serif;
            font-size: 28px;
            line-height: 40px;

            @media (max-width:1440px) {
                font-size: 22px;
                line-height: 30px;
            }

            @media (max-width:1199px) {
                font-size: 18px;
                line-height: 20px;
            }
        }
    }
}

.searchBar_container {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 140px;
    width: 100%;

    @media (max-width:1600px) {
        bottom: 120px;
    }

    @media (max-width:1366px) {
        bottom: 90px;
    }

    @media (max-width:1024px) {
        bottom: 50px;
    }

    @media (max-width:767px) {
        bottom: 0px;
    }

    .container {
        display: flex;

        .searchBar_wrapper {
            width: 100%;
            border-radius: 30px;
            border: 1px solid $light-full_White;
            padding: 10px;
            background: rgba(255, 255, 255, 0.5);
            position: relative;

            @media (max-width: 1440px) {
                border-radius: 16px;
            }

            @media (max-width: 1366px) {
                border-radius: 15px;
                padding: 8px;
            }

            @media (max-width: 1199px) {
                border-radius: 8px;
            }

            .autocomplete-dropdown {
                padding: 15px !important;
                border-radius: 30px !important;
                width: 100% !important;
                background: #fff;
                margin-top: 5px;
                position: absolute;
                z-index: 99;
                max-height: 400px;
                overflow: auto;

                @media (max-width:1600px) {
                    border-radius: 16px !important;
                }

                @media (max-width:1366px) {
                    border-radius: 12px !important;
                }

                @media (max-width:991px) {
                    border-radius: 8px !important;
                }



                button {
                    display: block;
                    width: 100%;
                    text-align: left;

                    &:hover {
                        background: $light-light_grey;
                    }

                    h3 {
                        color: $light-full_Black;
                        font-family: 'Gilroy-Medium', sans-serif;
                        font-size: 14px !important;
                        line-height: normal;
                        margin-bottom: 0;
                        background: transparent !important;
                    }

                    p {
                        color: $light-dark_grey;
                        font-family: 'Gilroy-Medium', sans-serif;
                        font-size: 12px;
                        line-height: normal;
                        margin-bottom: 0;
                        background: transparent !important;
                    }
                }

            }

            .searchBar {
                display: flex;
                align-items: center;
                border-radius: 20px;
                background: $light-full_White;
                box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
                padding: 0 8px 0 25px;

                @media (max-width: 1440px) {
                    border-radius: 12px;
                }

                @media (max-width: 1366px) {
                    border-radius: 8px;
                }

                .form-control {
                    width: 100%;
                    border: none;
                    outline: none;
                    color: $light-full_Black;
                    font-family: 'Gilroy-Medium', sans-serif;
                    font-size: 24px;
                    line-height: normal;
                    height: 70px;
                    background: $light-negative;

                    @media (max-width: 16000px) {
                        font-size: 20px;
                    }

                    @media (max-width: 1440px) {
                        height: 48px;
                        font-size: 16px;
                    }

                    @media (max-width: 1366px) {
                        height: 40px;
                        font-size: 14px;
                    }

                    @media (max-width: 767px) {
                        font-size: 12px;
                    }
                }
            }
        }

        button {
            svg {
                width: 22px;
                stroke: $light-full_Black;

                @media (max-width: 1366px) {
                    width: 17px;
                }
            }
        }
    }
}

.featuresSeaction {
    display: flex;
    justify-content: center;
    padding: 50px 0;

    @media (max-width:1199px) {
        padding: 30px 0;
    }

    .container {
        display: flex;
        align-items: flex-start;
        gap: 25px;

        @media (max-width: 767px) {
            flex-direction: column;
            gap: 20px;
        }

        .featuresWrap {
            display: flex;
            flex-direction: column;
            gap: 10px;

            @media (max-width: 767px) {
                align-items: center;
                width: 100%;
                gap: 5px;
            }

            p {
                color: #111;
                font-family: 'Gilroy-Light', sans-serif;
                font-size: 18px;
                line-height: 26px;

                @media (max-width: 1440px) {
                    font-size: 16px;
                    line-height: 20px;
                }

                @media (max-width: 1024px) {
                    font-size: 12px;
                    line-height: 16px;
                }

                @media (max-width: 767px) {
                    text-align: center;
                    width: 100%;
                }
            }

            h3 {
                display: flex;
                flex-direction: column;
                color: $light-full_Black;
                font-family: 'Gilroy-ExtraBold', sans-serif;
                font-size: 24px;
                line-height: 30px;
                text-transform: uppercase;

                @media (max-width: 1440px) {
                    font-size: 18px;
                    line-height: 22px;
                }

                @media (max-width: 991px) {
                    font-size: 16px;
                }

                @media (max-width: 767px) {
                    align-items: center;
                    gap: 5px;
                    justify-content: center;
                    width: 100%;
                    line-height: 18px;
                }

                small {
                    color: $light-chilly_red;
                    font-family: 'Gilroy-Regular', sans-serif;
                    font-size: 24px;

                    @media (max-width: 1440px) {
                        font-size: 18px;
                    }

                    @media (max-width: 991px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.offersContainer {
    position: relative;
    top: -1px;

    .slick-slider {
        .slick-list {
            overflow: hidden !important;
            width: 100%;
        }

        .slick-track {
            display: flex;
        }

        .slick-slide {
            width: auto !important;
            flex: 0 0 auto;
            max-height: 520px;
        }
    }

    @media (max-width:767px) {
        padding-top: 2%;

        .slider-container .item {
            width: 100% !important;
            padding: 0 10px;
        }
    }

    &.single-item {
        .slick-slider {
            .slick-track {
                display: flex !important;
                justify-content: center !important;
            }
        }
    }

    &::after {
        position: absolute;
        content: '';
        top: 0px;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: $light-chilly_red;
        z-index: -1;
    }

    .slick-arrow {
        display: flex !important;
        width: 46px !important;
        height: 45px !important;
        padding: 14px !important;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        border: 1px solid $light-light_grey !important;
        background: $light-negative url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-left-black.svg) no-repeat !important;
        background-position: center !important;
        z-index: 9;

        @media(max-width:1600px) {
            width: 40px !important;
            height: 40px !important;
            border-radius: 8px !important;
        }

        @media(max-width:1366px) {
            width: 30px !important;
            height: 30px !important;
            border-radius: 6px !important;
        }

        @media(max-width:767px) {
            display: none !important;
        }

        &:hover {
            background-color: $light-chilly_red !important;
            background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-left-white.svg) !important;
        }

        &::before {
            display: none !important;
        }

        &.isActive {
            background: $light-chilly_red url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-white.svg) !important;
        }

        &.slick-next {
            background:$light-negative url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-black.svg) no-repeat !important;
            background-position: center !important;

            &.isActive {
                background-image: $light-chilly_red url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-black.svg) !important;
            }

            &:hover {
                background-color: $light-chilly_red !important;
                background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-white.svg) !important;
            }
        }
    }

}

.storeSection {
    display: flex;
    justify-content: center;
    background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/ShapeTop.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    margin-top: -1px;
    padding-bottom: 10px;

    @media (max-width: 1366px) {
        padding-top: 0px;
    }

    .storeWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 15px 68px 0px rgba(0, 0, 0, 0.25);
        padding: 100px;
        gap: 50px;
        border-radius: 20px;
        background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/mobileBackground.png) lightgray 50% / cover no-repeat;
        overflow: hidden;
        margin-top: 100px;

        @media (max-width: 1600px) {
            margin-top: 70px;
        }

        @media (max-width: 1440px) {
            padding: 75px;
        }

        @media (max-width: 1366px) {
            padding: 55px;
            margin-top: 50px;
        }

        @media (max-width: 1280px) {
            border-radius: 10px;
            margin-top: 30px;
        }

        @media (max-width: 1024px) {
            padding: 35px;
        }

        @media (max-width: 767px) {
            padding: 15px;
            flex-direction: column;
            gap: 15px;
        }

        .imageBlock {
            img {
                margin-bottom: -28%;
                display: flex;
                height: auto;
                width: 432px;
                object-fit: contain;

                @media (max-width: 1600px) {
                    width: 360px;
                    margin-bottom: -32%;
                }



                @media (max-width: 1199px) {
                    margin-bottom: -39%;
                }


                @media (max-width: 767px) {
                    width: 350px;
                    display: unset;
                    margin-bottom: -9%;
                }

                @media (max-width: 480px) {
                    width: 350px;
                    display: unset;
                    margin-bottom: -11%;
                }
            }
        }

        .textBlock {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 25px;

            @media (max-width: 1280px) {
                gap: 20px;
            }

            @media (max-width: 767px) {
                width: 100%;
                gap: 15px;
            }

            h3 {
                color: $light-full_White;
                font-family: 'Gilroy-ExtraBold', sans-serif;
                font-size: 56px;
                line-height: 61px;
                text-transform: uppercase;

                @media (max-width: 1440px) {
                    font-size: 44px;
                    line-height: 46px;
                }

                @media (max-width: 800px) {
                    font-size: 26px;
                    line-height: 28px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    text-align: center;
                    font-size: 22px;
                }
            }

            p {
                color: #fff;
                font-family: 'Gilroy-Medium', sans-serif;
                font-size: 16px;
                font-style: normal;

                line-height: 22px;

                @media (max-width: 800px) {
                    font-size: 12px;
                    line-height: 18px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    text-align: center;
                }
            }

            .storeIcons {
                display: flex;
                gap: 10px;

                @media (max-width: 767px) {
                    justify-content: center;
                    flex-wrap: wrap;
                }

                svg {
                    @media (max-width: 800px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.populardish {
    border-bottom: 1px solid #d8d8d8;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding-left: calc((100% - 1200px) / 2);
    gap: 25px;

    @media (max-width: 1366px) {
        padding-left: calc((100% - 900px) / 2);
    }

    @media (max-width:1199px) {
        padding-top: 0px !important;
    }

    @media (max-width: 1024px) {
        padding-left: calc((100% - 750px) / 2);
    }

    @media (max-width: 800px) {
        padding-left: 30px;
        padding-right: 30px;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    @media (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .slick-list {
        padding: 0 !important;
    }

    .populardish-txt {
        max-width: calc(($containerWidth - 125px) / 3);
        display: flex;
        flex-direction: column;

        @media (max-width: 800px) {
            max-width: 100%;
            width: 100%;
            text-align: center;
        }

        h2 {
            color: $light-full_Black;
            font-family: 'Gilroy-ExtraBold', sans-serif;
            font-size: 56px;
            line-height: 61px;
            text-transform: uppercase;
            max-width: 260px;

            @media (max-width: 1440px) {
                font-size: 40px;
                line-height: 46px;
            }

            @media (max-width: 1366px) {
                font-size: 35px;
                line-height: 40px;
            }

            @media (max-width: 1024px) {
                font-size: 34px;
                line-height: 39px;
            }

            @media (max-width: 800px) {
                font-size: 28px;
                max-width: 100%;
                width: 100%;
                text-align: center;
            }
        }

        p {
            color: #111;
            font-family: 'Gilroy-Light', sans-serif;
            font-size: 22px;
            max-width: 280px;
            line-height: 30px;
            margin: 14px 0;

            @media (max-width: 1440px) {
                font-size: 16px;
                line-height: 26px;
                margin: 8px 0;
            }

            @media (max-width: 767px) {
                margin: auto;
                font-size: 14px;
            }
        }

        .populardish-arrow {
            margin: auto 0 0;

            @media (max-width:767px) {
                margin-top: 15px;
                display: none;
            }
        }

        .arrow-button {
            display: flex !important;
            width: 46px;
            height: 45px;
            padding: 14px;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            border: 1px solid $light-light_grey;
            position: relative;
            background:#faf9f7 url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-left-black.svg) no-repeat !important;
            background-position: center !important;
            top: 0;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: $light-chilly_red !important;
                background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-left-white.svg) !important;
            }

            @media (max-width: 1440px) {
                width: 35px;
                height: 35px;
                border-radius: 8px;
            }

            &::before {
                display: none;
            }

            &.slick-next {
                background:#faf9f7 url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-black.svg) no-repeat !important;
                background-position: center !important;
                right: 0;
                transform: translate(8px, 0);

                &:hover {
                    background-color: $light-chilly_red !important;
                    background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-white.svg) !important;
                }

            }
        }
    }

    .populardishSlider {
        flex-grow: 1;

        @media (max-width: 800px) {
            width: calc(100% + 30px) !important;
            margin-right: -30px;

            @media (max-width: 767px) {
                width: 100% !important;
                margin: 0;
            }
        }
    }

    .PopulardishSlider-container {
        width: 100%;

        .item {
            margin-right: 25px;
            width: 807px !important;
            position: relative;

            @media (max-width: 1366px) {
                width: 550px !important;
                margin-right: 20px;
            }

            @media (max-width: 1024px) {
                width: 482px !important;
                margin-right: 15px;
            }

            @media (max-width: 767px) {
                width: 100% !important;
                padding: 0;
            }

            &:focus-visible {
                outline: none;
            }

            img {
                cursor: pointer;
                width: 100%;
                height: auto;
                border-radius: 20px;

                @media (max-width: 1140px) {
                    border-radius: 15px;
                }

                @media (max-width: 1199px) {
                    border-radius: 8px;
                }

                @media (max-width: 767px) {
                    height: auto;
                }
            }

            .txt-block,
            .price-box {
                position: absolute;
            }

            .txt-block {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                top: 0;
                padding: 30px 30px 0 30px;

                @media (max-width:1199px) {
                    padding: 20px 20px 0 20px;
                }

                h4 {
                    color: $light-negative;
                    font-family: "Gilroy-ExtraBold", sans-serif;
                    font-size: 42px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: -0.42px;
                    text-shadow: 4px 2px 0px rgba(0, 0, 0, 0.55);

                    @media (max-width:1600px) {
                        font-size: 36px;
                    }

                    @media (max-width:1440px) {
                        font-size: 32px;
                    }

                    @media (max-width:1280px) {
                        font-size: 26px;
                    }

                    @media (max-width:1024px) {
                        font-size: 20px;
                    }

                    @media (max-width:991px) {
                        font-size: 16px;
                    }
                }
            }

            .price-box {
                color: $light-negative;
                text-align: center;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 22px;
                font-weight: 400;
                text-transform: uppercase;
                bottom: 30px;
                right: 30px;
                background: url('../../public/popular-shape-bg.svg') no-repeat;
                background-size: contain;
                width: 218px;
                height: 122px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 25px 40px 0px;

                @media (max-width:1680px) {
                    width: 180px;
                    height: 100px;
                    font-size: 20px;
                }

                @media (max-width:1440px) {
                    width: 160px;
                    height: 90px;
                    padding: 25px 30px 0px;
                }

                @media (max-width:1366px) {
                    width: 140px;
                    height: 77px;
                    font-size: 16px;
                }

                @media (max-width:1280px) {
                    width: 120px;
                    height: 67px;
                    padding: 20px 30px 0px;
                }

                @media (max-width:1199px) {
                    bottom: 20px;
                    right: 20px;
                }

                @media (max-width:767px) {
                    font-size: 14px;
                    width: 120px;
                    height: 67px;
                    padding: 18px 30px 0px;
                }

            }
        }

        .slick-arrow {
            display: flex !important;
            width: 46px;
            height: 45px;
            padding: 14px;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            border: 1px solid $light-light_grey;
            bottom: -17px;
            left: calc(-33% - 41px);
            top: unset;
            background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-left-black.svg) no-repeat;
            background-position: center !important;

            @media (max-width:1600px) {
                left: calc(-33% - 95px);
            }

            @media (max-width:1440px) {
                left: calc(-33% - 119px);
            }

            @media (max-width:1366px) {
                left: calc(-33% - 129px);
            }

            @media (max-width:1280px) {
                left: calc(-33% - 122px);
            }

            @media (max-width:1024px) {
                left: calc(-33% - 100px);
            }


            &::before {
                display: none;
            }

            &.isActive {
                background-color: $light-chilly_red !important;
                background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-white.svg);
            }

            &.slick-next {
                background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-black.svg) no-repeat;
                background-position: center;
                left: calc(-33% + 16px);

                @media (max-width:1600px) {
                    left: calc(-33% - 40px);
                }

                @media (max-width:1440px) {
                    left: calc(-33% - 66px);
                }

                @media (max-width:1366px) {
                    left: calc(-33% - 74px);
                }

                @media (max-width:1280px) {
                    left: calc(-33% - 68px);
                }

                @media (max-width:1024px) {
                    left: calc(-33% - 53px);
                }

                &.isActive {
                    background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/chevron-right-black.svg);
                }
            }
        }
    }
}

.client-says {
    display: flex;
    justify-content: center;
    background:$light-negative url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/shapebottom.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    padding-bottom: 100px;

    @media (max-width: 800px) {
        padding-bottom: 50px;
    }

    .slick-dots {
        bottom: -40px !important;
        text-align: left !important;
        position: relative !important;

        @media (max-width: 991px) {
            width: fit-content !important;
            bottom: -20px !important;
        }

        @media (max-width: 767px) {
            bottom: 0 !important;
        }

        li {
            width: 15px !important;
            height: 5px !important;
            margin: 0 2px !important;

            button:before {
                content: '' !important;
                width: 15px !important;
                height: 5px !important;
                border-radius: 5px;
                opacity: 0.75 !important;
                background-color: rgba(255, 255, 255, 0.13);
            }

            &.slick-active {
                width: 45px !important;
                height: 5px !important;
                margin: 0 2px !important;

                button:before {
                    opacity: 1 !important;
                    width: 45px !important;
                    height: 5px !important;
                    background-color: $light-amber;
                }
            }
        }
    }

    .container {
        padding: 0;

        .container {
            padding: 0;
        }

        @media (max-width: 800px) {
            padding: 0 30px;
        }

        @media (max-width: 767px) {
            padding: 0 15px;
        }

        .client-saysWrapper {
            border-radius: 50px;
            box-shadow: 0px 50px 73px 0px rgba(0, 0, 0, 0.25);


        }
    }
}

.client-says-wrap {
    border-radius: 50px;
    background: $light-chilly_red;
    box-shadow: 0px 50px 73px 0px rgba(0, 0, 0, 0.25);
    @include flex;
    justify-content: space-between;
    @include flex-align;

    @media (max-width:1600px) {
        border-radius: 30px;
    }

    @media (max-width:1199px) {
        border-radius: 20px;
    }

    @media (max-width:767px) {
        border-radius: 10px;
    }

    .client-says-img {
        display: flex;
        max-width: 50%;

        @media (max-width: 767px) {
            display: none;
        }

        img {
            border-radius: 50px;
            width: 644px;
            height: auto;
            max-width: 100%;
            object-fit: cover;

            @media(max-width:1600px) {
                border-radius: 30px;
            }

            @media(max-width:1366px) {
                height: 400px;
            }

            @media(max-width:1199px) {
                border-radius: 20px;
            }

            @media(max-width:1024px) {
                height: 375px;
            }
        }
    }

    .slick-slider {
        width: 50%;
        padding: 0 50px;

        @media (max-width:1440px) {
            padding: 0 30px;
        }

        @media (max-width: 767px) {
            width: 100%;
            margin-top: 0;
            padding: 30px;
        }

        @media (max-width: 480px) {
            padding: 15px;
        }
    }

    .client-says-txt {
        width: 50%;
        @include flex;
        align-items: center;
        @include flex-justify;
        @include flex-direction(column);
        gap: 30px;

        @media (max-width: 1366px) {
            gap: 15px;
        }

        .client-says-header {
            h2 {
                color: $light-negative;
                font-family: 'Gilroy-Bold', sans-serif;
                font-size: 42px;
                line-height: 38px;
                margin-bottom: 30px;

                @media (max-width: 1440px) {
                    font-size: 34px;
                    margin-bottom: 20px;
                }

                @media (max-width: 1366px) {
                    font-size: 24px;
                    margin-bottom: 5px;
                }

                @media (max-width: 991px) {
                    font-size: 20px;
                    margin-bottom: 5px;
                }
            }

            span {
                color: $light-negative;
                font-family: 'Gilroy-Light', sans-serif;
                font-size: 19px;
                line-height: 30px;
                margin-bottom: 10px;
                display: block;

                @media (max-width: 1366px) {
                    font-size: 16px;
                }

                @media (max-width: 1366px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        .client-says-body {
            p {
                color: $light-negative;
                font-family: 'Gilroy-Regular', sans-serif;
                font-size: 17px;
                line-height: 26px;
                font-style: italic;
                margin-bottom: 30px;
                display: block;

                @media (max-width: 1440px) {
                    margin-bottom: 20px;
                    font-size: 14px;
                    line-height: 22px;
                }

                @media (max-width: 1199px) {
                    font-size: 15px;
                }

            }
        }

        .client-says-footer {
            @include flex;
            @include flex-align;
            gap: 24px;

            @media (max-width: 1024px) {
                gap: 15px;
                @include flex-direction(column);
                @include flex-align(flex-start);
            }

            .userDetails {
                h4 {
                    color: $light-negative;
                    font-family: 'Gilroy-Medium', sans-serif;
                    font-size: 19px;
                    line-height: 26px;
                    margin-bottom: 5px;

                    @media (max-width: 1600px) {
                        font-size: 17px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 14px;
                        line-height: 17px;
                    }
                }

                .ratting-wrap {
                    @include flex;
                    @include flex-align;
                    gap: 15px;

                    @media (max-width: 1024px) {
                        gap: 10px;
                        margin-bottom: 15px;
                    }

                    p {
                        color: $light-negative;
                        font-family: 'Gilroy-Regular', sans-serif;
                        font-size: 16px;
                        line-height: 26px;

                        @media (max-width: 1024px) {
                            font-size: 12px;
                            line-height: 17px;
                        }
                    }

                    ul {
                        @include flex;
                        @include flex-align;
                        gap: 4px;

                        li {
                            @include flex;

                            svg {
                                width: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.profile-drawer {
    .overlay {
        opacity: 0;
        transition: .2s;
        pointer-events: none;
    }

    .drawer-wrapper {
        right: -590px;
        transition: .3s;
        position: fixed;
        top: 0;
        height: 100%;
        z-index: 1000;
        overflow: auto;
        width: 590px;
        background: #fff;

        @media (max-width:767px) {
            width: 100%;
            right: -100%;
        }

        .drawer {
            position: relative;
            width: 100%;
        }

        .overlay {
            display: none;
        }
    }

    &.true {
        .overlay {
            opacity: 1;
            transition: .2s;
        }

        .drawer-wrapper {
            right: 0px;
            transition: .3s;
        }
    }

}

section.drawer {
    display: flex;
    gap: 20px;
    flex-direction: column;
    position: fixed;
    background: #fff;
    right: 0;
    top: 0;
    height: 100%;
    padding: 50px;
    box-sizing: border-box;
    z-index: 1000;
    overflow: auto;
    width: 590px;

    @media (max-width:1600px) {
        padding: 30px;
    }

    @media (max-width:1199px) {
        padding: 15px;
    }

    @media (max-width:767px) {
        width: 100%;
        gap: 12px;
    }

    .sheetHeader {
        display: flex;
        align-items: center;

        .closeBtn {
            padding: 0;
            margin: auto;
            margin-right: 0;
        }

        .back-icon {
            display: flex;
            gap: 15px;
            align-items: center;

            .back-btn {
                padding: 0;
                display: flex;
                width: 32px;
                height: 32px;
                border-radius: 8px;
                border: 1px solid $light-light_grey;
                justify-content: center;
                align-items: center;
                transform-origin: center;
                transform: rotate(180deg);

                @media (max-width:1366px) {
                    width: 28px;
                    height: 28px;
                }

                svg {
                    width: 10px;
                    height: 10px;

                    @media (max-width:1366px) {
                        width: 8px;
                        height: 8px;
                    }

                }
            }
        }

        h4 {
            color: $light-full_Black;
            font-family: 'Gilroy-Bold', sans-serif;
            font-size: 24px;
            line-height: 38px;
            display: flex;
            flex-direction: column;

            @media (max-width:1600px) {
                font-size: 20px;
            }

            @media (max-width:1366px) {
                font-size: 18px;
            }

            small {
                color: $light-dark_grey;
                font-family: 'Gilroy-Regular', sans-serif;
                font-size: 14px;
                line-height: 23px;
            }
        }
    }

    .language_wrap {
        border-radius: 10px;
        border: 1px solid $light-board_grey;
        overflow: hidden;
        gap: 0 5px !important;
        padding: 4px;

        button {
            padding: 5px;
            border-radius: 6px;
            color: $light-full_Black;
            text-align: center;
            font-family: "Gilroy-Medium", sans-serif;
            font-size: 12px;
            line-height: 12px;

            &.active {
                color: $light-negative;
            }
        }
    }

    .profileDetails-wrap {
        background: $light-golden-gradient;
        border-radius: 8px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;

        @media (max-width:1440px) {
            gap: 10px;
        }

        .rowGroup {
            @media (max-width:767px) {
                flex-direction: column;
                gap: 10px;
            }
        }

        .profileDetails-header {
            display: flex;
            gap: 10px;
        }

        .profileDetails {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: space-between;

            h4 {
                color: $light-full_Black;
                font-family: 'Gilroy-Bold', sans-serif;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.5px;
                display: flex;
                flex-direction: column;

                @media (max-width:1366px) {
                    font-size: 14px;
                }

                small {
                    color: $light-dark_grey;
                    font-family: 'Gilroy-Regular', sans-serif;
                    font-size: 12px;
                    line-height: 28px;
                }
            }
        }

        .editBtn {
            color: $light-chilly_red;
            font-family: 'Gilroy-Medium', sans-serif;
            font-size: 12px;
            text-decoration-line: underline;
            text-transform: capitalize;
            padding: 0;
        }

        .btn-group {
            @media (max-width:767px) {
                flex-direction: column;
                gap: 10px;

                button {
                    width: 100%;
                }
            }
        }
    }

    .edit_Header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            color: $light-full_Black;
            text-align: center;
            font-family: 'Gilroy-Bold', sans-serif;
            font-size: 16px;
            line-height: normal;

            @media (max-width:767px) {
                font-size: 14px;
            }
        }
    }

    .settingsSection {
        display: flex;
        flex-direction: column;
        gap: 15px;

        @media (max-width:767px) {
            gap: 10px;
        }

        &.disabledField {
            .formControl {
                padding: 14px 0;
                border: none;
                background: transparent !important;
                color: $light-full_Black;
            }
        }

        ul {
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;

            li {
                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 0;

                    div {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        .menuText {
                            color: $light-full_Black;
                            text-align: center;
                            font-family: 'Gilroy-Medium', sans-serif;
                            line-height: normal;

                            @media (max-width:767px) {
                                font-size: 12px;
                            }

                            &.deleteAccount {
                                color: $light-chilly_red;
                                font-family: 'Gilroy-Medium', sans-serif;
                                font-size: 14px;
                                text-decoration-line: underline;
                            }
                        }
                    }
                }

                .active {
                    background-color: $light-chilly_red;
                    color: $light-full_White;
                }
            }
        }
    }

    .logoutProfile {
        display: flex;
        width: 100%;
        height: 50px;
        justify-content: center;
        align-items: center;
        background: $light-chilly_red;
        color: $light-negative;
        margin-top: auto;
        text-transform: unset;
        font-family: "Gilroy-medium", sans-serif;
        font-size: 14px;
        transition: all .5s ease-in-out;

        &:hover {
            background: $light-chilly_red_dark;
        }

        svg {
            path {
                stroke: $light-negative;
            }
        }

        @media (max-width:1440px) {
            height: 44px;
        }
    }

    &.address-book {
        .sheetHeader {
            .back-icon {
                flex: 1 1 100%;
            }
        }

        .location-wrap {
            .saved-locations {
                max-height: 100%;
                margin-top: 0;
                height: 100%;
                overflow: unset;

                h4 {
                    font-size: 14px;
                    margin: 0;
                }

                .item-locations {
                    border-radius: 12px;
                    padding: 20px;
                    flex-direction: row;
                    align-items: center;

                    @media (max-width:1440px) {
                        padding: 12px;
                        border-radius: 8px;
                    }

                    @media (max-width:1199px) {
                        padding: 8px;
                        border-radius: 4px;
                    }
                }

                .item-header {
                    h6 {
                        font-size: 12px;
                    }
                }

                .item-locations p {
                    color: $light-full_Black;
                }
            }

            .submint-btn {
                width: auto;
                margin: auto;
                margin-right: 0;
                background: $light-chilly_red;
                transition: all .5s ease-in-out;

                &:hover {
                    background: $light-chilly_red_dark;
                }
            }
        }
    }
}

.orderCardList {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    padding-right: 15px;
    margin-right: -25px;

    @media (max-width:767px) {
        margin-right: 0;
        padding-right: 10px;
    }

    li.list {
        border-bottom: solid 1px #d7d7d7;
        padding: 20px 0;
    }
}

.categorySlider-container {
    display: flex;
    justify-content: center;

    .slick-list {
        padding: 50px 0 !important;

        @media (max-width:1366px) {
            padding: 40px 0 !important;
        }

        @media (max-width:1199px) {
            padding: 30px 0 !important;
        }
    }

    .slick-dots {
        display: none !important;
    }
}




footer {
    display: flex;
    align-items: center;
    padding: 80px 0;
    flex-direction: column;
    gap: 30px;
    margin-top: -25px;
    background: $light-negative;
    border-radius: 20px;

    @media (max-width: 1440px) {
        padding: 60px 0;
        gap: 20px;
        border-radius: 14px;
    }

    @media (max-width: 991px) {
        padding: 50px 0;
        border-radius: 8px;
    }

    @media (max-width: 767px) {
        padding: 30px 0;
    }

    .container {
        width: 1200px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        gap: 100px;

        @media (max-width: 1366px) {
            width: 900px;
        }

        @media (max-width: 1024px) {
            width: 750px;
        }

        @media (max-width: 800px) {
            width: 100%;
            padding: 0 50px;
        }

        @media (max-width: 767px) {
            flex-direction: column;
            gap: 20px;
            padding: 0 15px;
        }

        &:nth-child(2) {
            border-top: 0.5px solid #d4cc27;
            padding-top: 10px;
            align-items: center;

            @media (max-width: 1440px) {
                padding-top: 30px;
            }
        }

        p {
            color: #909090;
            font-family: 'Gilroy-Regular', sans-serif;
            font-size: 16px;
            line-height: 28px;

            @media (max-width: 1366px) {
                font-size: 14px;
                line-height: 22px;
            }

            @media (max-width: 1024px) {
                font-size: 12px;
                line-height: 17px;
            }
        }

        .width33 {
            display: flex;
            flex-direction: column;
            gap: 30px;

            @media (max-width: 767px) {
                width: 100%;
            }

            .footerlogowrap {
                display: flex;
                justify-content: space-between;

                img {
                    &:first-child {
                        width: 190px;
                        height: auto;
                    }

                    &:nth-child(2) {
                        width: 72px;
                        height: auto;
                    }
                }
            }
        }

        .width66 {
            display: flex;
            gap: 25px;

            @media (max-width: 767px) {
                width: 100%;
            }

            @media (max-width: 538px) {
                flex-direction: column;
            }

            ul {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                gap: 20px;

                @media (max-width: 1366px) {
                    gap: 15px;
                }

                li {
                    h4 {
                        color: #000;
                        font-family: 'Gilroy-Bold', sans-serif;
                        font-size: 20px;

                        @media (max-width: 1366px) {
                            font-size: 16px;
                        }
                    }

                    a,
                    div {
                        color: #7c7c7c;
                        font-family: 'Gilroy-Regular', sans-serif;
                        font-size: 16px;
                        transition: all .3s;

                        &:hover {
                            color: $light-chilly_red;
                            transition: all .3s;
                        }

                        @media (max-width: 1440px) {
                            font-size: 14px;
                        }

                        @media (max-width: 1024px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .copyRight {
            @media (max-width: 1024px) {
                font-size: 12px;
            }
        }

        ul.social {
            display: flex;
            gap: 30px;

            li {
                a {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 17px;
                        height: 17px;
                    }
                }
            }
        }
    }
}

// Auth Styles

.signinContainer {
    width: 100%;
    display: flex;
    height: 100vh;

    @media (max-width:991px) {
        flex-direction: column;
    }

    .rowGroup {
        @media (max-width:991px) {
            flex-direction: column;
        }
    }

    .imageContainer {
        width: 50%;
        background-color: $light-chilly_red;
        flex-grow: 1;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;

        &::after {
            content: "";
            display: block;
            position: absolute;
            background: #000;
            width: 150px;
            height: 150px;
            right: -40px;
            bottom: -80px;
            filter: blur(22px);

            @media (max-width:1660px) {
                bottom: -60px;
            }
        }

        @media (max-width:991px) {
            width: 100%;
            padding: 30px;
            height: 450px;
        }

        video {
            width: 115%;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            @media (max-width:1366px) {
                width: 124%;
            }

            @media (max-width:991px) {
                width: 100%;
            }

            @media (max-width:767px) {
                width: 120%;
            }
        }
    }

    .fieldContainer {
        width: 50%;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width:991px) {
            width: 100%;
            padding: 20px;
            height: 400px;
        }

        @media (max-width:767px) {
            align-items: flex-start;
        }

        .country-select {
            .phone-input {
                border-radius: 8px;

                button {
                    img {
                        max-width: 25px;
                        margin-right: 5px;

                    }
                }
            }
        }

        .countries-list {
            button {
                img {
                    max-width: 25px;
                    margin-right: 5px;
                }
            }
        }

        .backButton {
            position: absolute;
            top: 50px;
            right: 50px;
            background-color: $light-full_Black;
            color: $light-full_White;
            font-family: "Gilroy-Bold", sans-serif;
            font-size: 16px;
            text-transform: uppercase;
            display: flex;
            height: 38px;
            width: 72px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            padding: 0 !important;

            @media (max-width:767px) {
                top: 20px;
                right: 20px;
                font-size: 14px;
                height: 34px;
                width: 64px;
            }
        }

        .formField {
            width: 350px;
            display: flex;
            flex-direction: column;
            gap: 25px;

            @media (max-width:991px) {
                gap: 20px;
            }

            h3 {
                color: $light-full_Black;
                font-family: 'Gilroy-Bold', sans-serif;
                font-size: 36px;
                font-style: normal;
                line-height: 44px;
                display: flex;
                flex-direction: column;
                font-weight: 400;

                @media (max-width:991px) {
                    font-size: 30px;
                }

                @media (max-width:767px) {
                    font-size: 26px;
                }

                small {
                    color: $light-full_Black;
                    font-family: 'Gilroy-Regular', sans-serif;
                    font-size: 18px;
                    line-height: 24px;
                    margin-top: 20px;
                    max-width: 260px;

                    @media (max-width:1199px) {
                        font-size: 16px;
                        line-height: 24px;
                        margin-top: 10px;
                        max-width: 240px;
                    }

                    @media (max-width:991px) {
                        font-size: 14px;
                        max-width: 100%;
                    }
                }
            }

            .formGroup {
                label {
                    .optional-grey {
                        color: $light-dark_grey;
                    }
                }
            }

            .search-input-wrapper {
                .search-input {
                    @media (max-width:767px) {
                        margin-top: 0;
                    }
                }
            }

            .countries-list {
                .country-option {
                    padding: 4px 8px !important;
                    height: 30px;
                }
            }

            button {
                display: flex;
                width: 100%;
                height: 43px;
                justify-content: center;
                align-items: center;
                background: $light-chilly_red;
                color: $light-full_White;
                border-radius: 12px;
                transition: all 0.5s ease-in-out;
                font-family: "Gilroy-Bold", sans-serif;
                font-size: 12px;

                &:hover,
                &:active {
                    background: $light-chilly_red_dark;

                }
            }
        }

        .formGroup {
            label {
                color: $light-full_Black;
                font-family: "Gilroy-Medium", sans-serif;
            }
        }
    }

    &.verificationContainer {
        h3 {
            font-size: 24px !important;
            line-height: 44px !important;

            @media (max-width:991px) {
                font-size: 20px !important;
            }
        }

        small {
            font-size: 14px !important;
            max-width: 100% !important;
            margin: 0 !important;
        }
    }
}

// Common Styles

.avatar {
    display: flex;
    width: 59px;
    height: 58px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
    border: 2px solid $light-amber;

    @media (max-width:1440px) {
        width: 50px;
        height: 50px;
        border-radius: 8px;
    }

    .avatar_icon {
        display: flex;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 12px;
        background: $light-amber;

        @media (max-width:1440px) {
            border-radius: 8px;
        }

        @media (max-width:767px) {
            border-radius: 5px;
        }

        span {
            color: #fff;
            font-family: 'Gilroy-Bold', sans-serif;
            font-size: 20px;
            line-height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.toggle-container {
    position: relative;
    width: 44px;
    height: 26px;
    padding: 3px;
    border-radius: 10px;
    border: 1px solid $light-board_grey;
    display: flex;

    &.languageSelector {
        .dialog-button {
            width: 26px;
            left: 13px;
        }

        .disabled {
            background-color: $light-board_grey;
            left: 3px;
        }
    }
}

.dialog-button {
    position: absolute;
    left: 20px;
    transition: all 0.3s ease;
    color: $light-full_White;
    font-size: 12px;
    font-family: 'Gilroy-Regular', sans-serif;
    width: 18px;
    height: 18px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid var(--Shade-Prime, rgba(250, 248, 244, 0.07));
    background: var(--Chilly-Red, #be1622);
}

.disabled {
    background-color: #707070;
    left: 3px;
}

.icon {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.delivery-icon {
        width: 24px;
        height: 24px;

        svg {
            max-width: 24px;
            width: 24px;
            height: 24px;
        }
    }

    &.chevron {
        svg {
            height: 12px;
            width: 12px;
        }
    }

    svg {
        max-width: 22px;
    }
}

.overlay {
    background: rgba(11, 14, 23, 0.51);
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    backdrop-filter: blur(11.5px);
}

.container {
    width: 1300px;
    padding: 0 50px;

    @media (max-width: 1366px) {
        width: 1000px;
    }

    @media (max-width: 1366px) {
        width: 1000px;
    }

    @media (max-width: 1024px) {
        width: 850px;
    }

    @media (max-width: 800px) {
        width: 100%;
        padding: 0 30px;
    }

    @media (max-width: 767px) {
        width: 100%;
        padding: 0 15px;
    }
}

.formGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
        color: $light-dark_grey;
        font-family: 'Gilroy-Medium', sans-serif;
        font-size: 12px;
        font-style: normal;
        line-height: normal;

        span {
            color: $light-chilly_red;
        }
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background: #fff url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/down-arrow.svg) no-repeat !important;
        background-position: right 10px center !important;
    }

    .formControl {
        border-radius: 8px;
        border: 1px solid var(--Grey, #e6e6e6);
        background: var(--Negative, #fff);
        height: 44px;
        padding: 14px;
        font-family: 'Gilroy-Medium', sans-serif;
        font-size: 14px;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
}

button {
    padding: 12px 14px 10px;
    border-radius: $border-radius-small;
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 15px;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: left;

    @media (max-width: 1440px) {
        padding: 11px 12px 9px;
        font-size: 12px;
    }

    &.primary_btn {
        background-color: $light-chilly_red;
        color: $light-full_White;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: $light-chilly_red_dark !important;
        }
    }

    &.default_btn {
        background-color: $light-full_Black;
        color: $light-full_White;
    }
}

img {
    max-width: 100%;
}

.rowGroup {
    display: flex;
    gap: 15px;
}

.width75 {
    width: calc(100% - 25%);
}

.width50 {
    width: calc(100% / 2);
}

.width25 {
    width: calc(100% / 4);
}

.width33 {
    width: calc(100% / 3);
}

.width66 {
    width: calc(100% / 3 * 2);
}

// Common Styles

.PhoneInputInput {
    background: var(--Negative, #fff);
    height: 42px;
    border: none;
    width: 100%;
    outline: none;
}

.PhoneInput {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--Grey, #E6E6E6);
    background: var(--Negative, #FFF);
    height: 44px;
    padding: 0px 15px;
}

.resend-txt {
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: $light-dark_grey;

    em {
        font-style: normal;
        text-decoration: underline;
    }

    &.isActive {
        color: $light-full_Black !important;
    }
}

.PhoneInputCountryIcon--border {
    box-shadow: none !important;
    border-radius: 2px;
    overflow: hidden;
}

.PhoneInputCountrySelectArrow {
    display: none !important;
}

.PhoneInputCountry {
    margin-right: 15px !important;
}

.otpInput {
    display: flex;
    flex-direction: column;
    gap: 15px;

    div {
        display: flex;
        gap: 15px;

        @media (max-width: 767px) {
            gap: 5px;
        }
    }

    input {
        width: 100% !important;
        text-align: center;
        border-radius: 8px !important;
        border: 1px solid #c2c2c2 !important;
        background: var(--Negative, #fff) !important;
        height: 44px !important;
        padding: 0px 15px !important;

        &:focus-visible {
            outline: none !important;
        }
    }

    span {
        display: none;
    }
}

.address-type-btn {
    text-transform: none;
}

.termsListRomanStyle {
    margin-left: 50px;

    @media (max-width: 1600px) {
        margin-left: 30px;
    }

    @media (max-width: 1199px) {
        margin-left: 20px;
    }

    @media (max-width: 767px) {
        margin-left: 15px;
    }
}

.address-container {
    display: 'flex' !important;
    gap: '15rem' !important;
}