
.rtlMode {
    direction: rtl;

    * {
        font-family: 'Cairo', sans-serif !important;
    }

    header .top_controls_wrapper .mode_switcher .language_wrap button {
        font-weight: 800;
    }


    header .header_wrapper .container .authControls .default_btn,
    header .header_wrapper .container .authControls .primary_btn,
    header .top_controls_wrapper .location_update .change-btn,
    .notifications-modal .chicking-modal-footer .btn-group button {
        min-width: 100px !important;
        text-align: center;
        display: block;
    }

    header .top_controls_wrapper .location_update .change-btn {
        @media (max-width:520px) {
            min-width: 80px !important;
        }
    }

    header .header_wrapper .container .authControls .primary_btn,
    header .header_wrapper .container .authControls .default_btn {
        padding: 5px 14px 10px !important;

        @media (max-width:1440px) {
            padding: 5px 10px !important;
        }

        @media (max-width:1366px) {
            padding: 7px 10px !important;
        }
    }

    button {
        font-weight: 600;
    }

    .checkbox input:checked+span:after {
        top: 7px;
        left: -1px;
    }

    .item-locations {
        .checkbox input {
            top: 8px;
        }
    }

    .schedule-time-wrap {
        .time-picker {
            gap: 10px;
        }
    }

    .category-detail-modal {
        .toggle-button-cover .slider:before {
            right: 2px;
        }

        .slider::after {
            right: 8px !important;
        }

        .chicking-modal-footer .checkbox input:checked+span:after {
            top: 3px;
            left: -1px;

            @media (max-width:1600px) {
                top: 4px;
            }

            @media (max-width:991px) {
                top: 11px;
                background-size: 10px;
            }
        }

        input:checked+.slider:before {
            transform: translateX(-18px);
        }

        input:checked+.slider::after {
            transform: translateX(-18px);
        }
    }

    .featuresSeaction {
        .container {
            .featuresWrap {
                p {
                    font-weight: 300;
                }

                h3 {
                    font-weight: 700;

                    small {
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .carousel_wrapper .container .hero_title button {
        font-weight: 400;
    }

    .populardish {
        .populardish-txt {
            h2 {
                font-weight: 700;
            }

            p {
                font-weight: 300;
            }
        }
    }

    .coupon-txt svg,
    .settingsSection .icon.chevron svg {
        transform: rotate(180deg);
    }

    .coupon-txt button svg {
        transform: rotate(0deg);
    }

    .chicking-modal {
        .chicking-modal-wraper {
            padding-right: 40px;
            padding-left: 30px;

            @media (max-width:1600px) {
                padding: 30px;
                padding-right: 20px;
                gap: 15px;
            }

            @media (max-width:1366px) {
                padding: 20px;
                padding-right: 15px;
            }

            @media (max-width:991px) {
                gap: 10px;
                padding: 15px;
            }

            .chicking-modal-header {
                padding: 0 10px;

                .chicking-modal-title {
                    h4 {
                        font-weight: 400;
                    }

                    button {
                        margin: auto;
                        margin-left: 0;
                        line-height: 0;
                    }

                    .back-btn {
                        transform: rotate(0deg);
                    }
                }

                p {
                    font-weight: 400;
                }
            }

            .chicking-modal-body {
                .submint-btn {
                    font-weight: 400;
                }
            }

            .chicking-modal-footer {
                padding-right: 0px;

                .btn-group {
                    button {
                        font-weight: 400;
                        white-space: nowrap;
                        min-width: 140px;
                    }
                }
            }
        }

        .submint-btn {
            margin-right: 0;
        }
    }

    .chicking-modal-body {
        padding-right: unset !important;
        padding-left: 10px;
    }

    .location-wrap .saved-locations h4,
    .location-wrap .recent-search h4 {
        text-align: right;
    }

    .orderCardList {
        margin: 0;
        padding: 0;
        padding-left: 15px;
        margin-left: -25px;
    }

    // font weight

    .storeSection {
        background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/ShapeTop-rtl.svg);

        .storeWrapper {
            .textBlock {
                h3 {
                    font-weight: 700;
                }

                p {
                    font-weight: 500;
                }
            }
        }
    }

    .client-says {
        background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/shapebottom-rtl.svg);

        .container {
            .client-saysWrapper {
                .client-says-wrap {
                    .client-says-txt {
                        .client-says-header {
                            h2 {
                                font-weight: 400;
                            }

                            span {
                                font-weight: 400;
                            }
                        }

                        .client-says-body {
                            p {
                                font-weight: 400;
                            }
                        }

                        .client-says-footer {
                            .userDetails {
                                h4 {
                                    font-weight: 400;
                                }

                                .ratting-wrap {
                                    p {
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .items {
        span {
            font-weight: 500;
        }
    }

    .client-says-wrap {
        .client-says-txt {
            .client-says-header {
                h2 {
                    font-weight: 400;
                    text-align: right;
                }

                span {
                    font-weight: 400;
                    text-align: right;
                    display: block;
                }
            }

            .client-says-body {
                p {
                    font-weight: 400;
                    text-align: right;
                }
            }

            .client-says-footer {
                justify-content: flex-start;
                flex-direction: row-reverse;

                @media (max-width: 1024px) {
                    flex-direction: column !important;
                    margin: auto;
                    margin-right: 0;

                    img {
                        margin: auto;
                        margin-right: 0;
                    }

                    .userDetails {
                        h4 {
                            text-align: right;
                            font-weight: 400;
                        }

                        .ratting-wrap {
                            flex-direction: row-reverse;
                        }
                    }
                }

                .userDetails {
                    h4 {
                        font-weight: 400;
                    }

                    .ratting-wrap {
                        p {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .location-wrap {
        .location-block {
            .current-location-txt {
                font-weight: 400;
            }

            .new-address-btn {
                font-weight: 400;
            }

            .input-group {
                button {
                    text-align: right;
                }
            }
        }

        .saved-locations {
            padding-right: 0 !important;
            margin-left: 7px;
            padding-left: 8px;

            .checkbox input:checked+span:after {
                top: 4px;
                left: -1px;
            }

            h4 {
                font-weight: 400;
            }

            .item-locations {
                .item-header {
                    button {
                        font-weight: 400;
                    }
                }

                p {
                    font-weight: 400;
                }
            }
        }

        .recent-search {
            h4 {
                font-weight: 400;
            }

            .recent-search-list {
                span {
                    font-weight: 400;
                }
            }
        }

        .location-map {
            .delivery-address {
                h6 {
                    font-weight: 600;

                    span {
                        font-weight: 700;
                    }
                }

                .btn-change {
                    font-weight: 400;
                    min-width: 80px;
                    border: 1px solid #919191
                }
            }
        }

        .form-block {
            .save-list-wrap {
                h4 {
                    font-weight: 500;
                }

                .save-list {
                    span {
                        font-weight: 400;
                    }
                }
            }
        }

        .take-away-search {
            .item-locations {
                .left-block {
                    h6 {
                        font-weight: 800;
                    }

                    div {
                        span {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .schedule-time {
        h5 {
            font-weight: 400;
        }

        .submint-btn {
            font-weight: 400;
        }

        .time-wrap {
            span {
                font-weight: 400;
            }

            .time-time {
                .btn-group {
                    .btn {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .category-detail {
        .category-detail-header {
            .category-detail-carousel {
                .category-detail-list {
                    .offer-block {
                        h6 {
                            font-weight: 800;
                        }
                    }

                    .offer-block-txt {
                        h4 {
                            font-weight: 700;
                            text-align: right;
                        }

                        ul {
                            li {
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }

        .category-detail-banner {
            .title-block {
                h4 {
                    font-weight: 900;
                }

                p {
                    font-weight: 400;
                }
            }

            .txt-block {
                h3 {
                    font-weight: 800;

                    span {
                        font-weight: 500;
                    }
                }

                .btn-group {
                    .cart-btn {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .category-detail-card {
        .img-block {
            .like-btn-block {
                h6 {
                    font-weight: 800;
                }
            }

            .add-btn {
                font-weight: 400;
            }

            .like-btn {
                right: unset !important;
                left: 10px;
            }
        }

        .txt-block {
            gap: 15px;

            .category-name {
                text-align: right;
            }

            h4 {
                font-weight: 800;
            }

            .veg-block {
                span {
                    font-weight: 400;
                }

                p {
                    font-weight: 400;
                }
            }
        }
    }

    .category-detail-banner-bg {
        background: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/rtl-shapebottom.svg) no-repeat !important;
        background-size: cover !important;
    }

    .order-type-modal {
        .chicking-modal-wraper {
            .chicking-modal-header {
                h4 {
                    font-weight: 400;
                }

                p {
                    font-weight: 400;
                }
            }

            .order-type-list {
                li {
                    figure {
                        figcaption {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .order-details-wrap {
        .order-list {
            h4 {
                font-weight: 800;
            }

            .quantity-wrap {
                h5 {
                    font-weight: 800;
                }
            }
        }
    }

    .choice-chicken-wrap {
        h4 {
            font-weight: 400;
            text-align: right;
        }

        .choice-list-wrap {
            .list {
                .txt-block {
                    h5 {
                        font-weight: 700;
                    }

                    span {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .offer-details-wrap {
        .apy-btn {
            font-weight: 400;
        }

        .formControl {
            padding-left: 10px !important;
        }

        .input-group {
            padding: 12px 15px;
            padding-left: 7px;
        }
    }

    .offer-list-wrap {
        .offer-list {
            .list-block {
                .offer-list-txt {
                    h6 {
                        font-weight: 400;
                    }

                    a,
                    p {
                        font-weight: 400;
                        text-align: right;
                    }
                }

                .offer-block {
                    h4 {
                        font-weight: 400;
                    }

                    span {
                        font-weight: 400;
                    }
                }
            }

            p {
                font-weight: 400;
            }
        }
    }

    .ordertracking {
        .sub-title {
            font-weight: 400;
        }

        .remarks-block {
            p {
                font-weight: 400;
            }
        }
    }

    .cartitems-wrap {
        .cartitems-title {
            font-weight: 400;
        }

        .cartitems-list {
            .cartitem {
                .link-wrap {
                    .cart-link {
                        font-weight: 400;
                    }
                }

                .cart-details {
                    .title-box {
                        h4 {
                            font-weight: 400;
                        }
                    }

                    .quantity-details {
                        span {
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        .radio-box input:checked+span:after {
            top: 4px;
            left: -1px;
        }
    }

    .addmoreitems-wrap {
        .addmoreitems {
            .addmoreitems-details {
                h6 {
                    font-weight: 700;
                }

                p {
                    font-weight: 400;
                }

                span {
                    font-weight: 400;
                }

                button {
                    font-weight: 400;
                    margin: unset !important;
                    align-self: flex-start;
                }
            }
        }
    }

    .tab-container {
        .tabs {
            .tab {
                h4 {
                    font-weight: 400;
                }
            }
        }
    }

    .cart-billing-details {
        ul {
            li {
                span {
                    font-weight: 400;

                    small {
                        font-weight: 400;
                    }
                }

                &.total-txt {
                    font-weight: 400;
                }
            }
        }

        .checkout-btn {
            font-weight: 400;
        }
    }

    .coupon-txt {
        h5 {
            font-weight: 400;
        }
    }

    .order-id {
        ul {
            li {
                span {
                    font-weight: 400;
                }
            }
        }
    }

    .track-order-wrap {
        .order-tracking-wrap {
            ul {
                li {
                    span {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .delivery-block {
        span {
            font-weight: 400;
        }
    }

    .order-address-wrap {
        .order-address {
            ul {
                li {
                    div {
                        span {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .order-item-wrap {
        .order-item {
            .order-count {
                span {
                    font-weight: 400;
                }
            }
        }
    }

    .cartitems-remarks {
        h4 {
            font-weight: 400;
        }

        textarea {
            font-weight: 400;
        }
    }

    .payment-wrap-modal {
        .payment-wrap {
            .checkbox input {
                top: 8px;
            }

            .checkbox input:checked+span:after {
                top: 3px;
            }

            .payment-list {
                span {
                    font-weight: 700;

                    small {
                        font-weight: 400;
                    }
                }
            }
        }
    }


    .category-detail-modal {
        .chicking-modal-wraper {
            padding: 0;
            gap: 0;

            .chicking-modal-header {
                padding: 10px 10px 5px 10px;

                .chicking-modal-title {
                    button {
                        top: 15px;
                        right: 30px;

                        @media (max-width:1366px) {
                            margin: auto;
                            margin-right: 0;
                            position: relative;
                            inset: 0;
                        }

                    }
                }
            }

            .chicking-modal-body {
                padding-left: 0;
            }

            .detail-modal-wrap {
                padding-left: 0;

                .left-block {
                    .img-block {
                        .offer-block {
                            .offer-txt {
                                font-weight: 800;
                            }
                        }
                    }

                    .txt-block {
                        h3 {
                            font-weight: 400;
                        }

                        .graphic-block-wrap {
                            span {
                                font-weight: 400;
                            }
                        }
                    }

                    .category-quantity {
                        span {
                            font-weight: 700;
                        }
                    }

                    .description-block {
                        h4 {
                            font-weight: 400;
                        }

                        p {
                            font-weight: 400;

                            a {
                                font-weight: 600;
                            }
                        }
                    }
                }

                .right-block {
                    padding-left: 20px;

                    @media (max-width:767px) {
                        padding-left: 0;
                    }

                    .choice-list-wrap {
                        padding-right: unset;
                        padding-left: 10px;
                    }

                    .choice-title {
                        .tab-contents .tab-content {
                            padding-right: 0px;
                            padding-left: 10px;
                        }

                        h4 {
                            font-weight: 400;
                        }

                        .selected-block {
                            .icon-block {
                                li {
                                    span {
                                        font-weight: 400;
                                    }
                                }
                            }

                            h6 {
                                font-weight: 400;
                            }
                        }
                    }

                    .cartitems-list {
                        .cartitem {
                            .cart-details {
                                .title-box {
                                    h4 {
                                        font-weight: 700;
                                        text-align: right;
                                    }

                                    span {
                                        font-weight: 400;
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .icon-block li:last-child {
            margin-right: unset !important;
            margin-left: 0 !important;
        }

        .chicking-modal-footer {
            .checkbox {
                p {
                    font-weight: 400;
                }
            }
        }

        .radio-box {
            input:checked+span:after {
                left: -1px;
            }
        }
    }

    .toggle-button-cover {
        span {
            font-weight: 400;
        }
    }

    .outlets-items {
        figure {
            figcaption {
                transform: translate(0px, 76px);

                h6 {
                    font-weight: 400;
                    text-align: right;

                    span {
                        font-weight: 400;
                    }
                }

                .btn-group {
                    flex-direction: row-reverse;

                    .btn {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    header {
        .top_controls_wrapper {
            .location_update {
                span {
                    font-weight: 500;
                }
            }
        }

        .header_wrapper {
            .container {
                .authControls {
                    .cartIconwrap {
                        h4 {
                            font-weight: 600;
                            padding: 0px 0 0px 15px;

                            small {
                                font-weight: 500;
                            }
                        }
                    }

                    .userDatawrap {
                        h4 {
                            font-weight: 600;

                            small {
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }

    .carousel_wrapper {
        background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/shapebottom-rtl.svg);

        .container {
            .hero_title {
                h1 {
                    font-weight: 700;

                    small {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .searchBar_container {
        .container {
            .searchBar_wrapper {
                .searchBar {
                    padding: 0 25px 0 8px;

                    .form-control {
                        font-weight: 500;
                    }
                }
            }
        }
    }

    section.drawer {
        .closeBtn {
            margin: auto !important;
            margin-left: 0px !important;
        }

        .back-btn {
            transform: rotate(0deg) !important;
        }

        .sheetHeader {
            h4 {
                font-weight: 600;

                small {
                    font-weight: 500;
                }
            }

            .logoutProfile {
                font-weight: 400;
            }
        }

        .profileDetails {
            h4 {
                font-weight: 600;

                small {
                    font-weight: 400;
                }
            }
        }

        .edit_Header {
            .editBtn {
                font-weight: 500;
            }

            h4 {
                font-weight: 600;
            }
        }

        .settingsSection {
            ul {
                li {
                    a {
                        div {
                            .menuText {
                                font-weight: 500;

                                &.deleteAccount {
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .outlets {
        h4 {
            font-weight: 700;

            small {
                font-weight: 500;
            }
        }
        h5{
            text-align: right;
        }
    }

    .signinContainer {
        .fieldContainer {
            .formField {
                h3 {
                    font-weight: 600;

                    small {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .dialog-button {
        font-weight: 400;
    }

    .formGroup {
        label {
            font-weight: 500;
        }

        select {
            background-position: left 10px center !important;
            padding: 0 14px;
        }
    }

    footer {
        .container {
            p {
                font-weight: 400;
            }

            .copyRight {
                color: $dark-dark_grey;
            }

            .width66 {
                ul {
                    li {
                        h4 {
                            font-weight: 600;
                        }

                        a {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .address-book {
        .dropdown-btn {
            margin: auto;
            margin-left: 0;

            .dropdown {
                position: absolute;
                top: 0;
                left: 0;
                right: unset;

                ul::after {
                    top: -8px;
                    right: unset;
                    left: 5px;
                }
            }
        }
    }

    .cart-section {
        background-image: url(#{$NEXT_PUBLIC_CDN_URL}/web-app/assets/images/shapebottom-rtl.svg);
    }

    .signinContainer .fieldContainer .backButton {
        left: 50px;
        right: unset;

        @media (max-width:767px) {
            left: 30px;
        }
    }

    @media (max-width:480px) {
        .Toastify__toast-container {
            width: 90vw;
            right: 0;
        }
    }

    .otpInput {
        div {
            direction: ltr;
        }
    }

    .direction-ltr {
        direction: ltr;
    }

    .text-right {
        text-align: right !important;
    }

    .cartitems-item .list p {
        flex: unset !important;
        text-align: right;
    }

    .billing-details-wrap {
        .radio-box input:checked+span:after {
            top: 4px !important;
            left: -1px;
            @media (max-width:1366px) {
                top: 4px !important;
            }
            @media (max-width:1280px) {
                left: 0px !important;
            }
        }
    }

    .logoutIcon {
        margin: 0;
        margin-left: 10px;
    }

    .searchBar_container .container .searchBar_wrapper .autocomplete-dropdown button h3,
    .searchBar_container .container .searchBar_wrapper .autocomplete-dropdown button p {
        text-align: right;
    }

    .cartitems-wrap.cart-billing-details.billing-details-wrap {
        top: 4px !important;

        @media (max-width:1280px) {
            left: 0 !important;
        }
    }

    .profileDetails-wrap .formGroup .formControl,
    section.drawer .settingsSection.disabledField .formControl,
    .drawer.address-book .country-select .phone-number-input {
        text-align: right;
    }

}